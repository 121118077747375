export default theme => ({
    logo: {
        margin: '5px'
    },
    flexGrow: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'center',
        flexGrow: 1
    },
    signOutButton: {
        '& span': {
          marginLeft: '5px',
          fontSize: '12px'
        }
    }
  });