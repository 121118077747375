export default theme => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 20,
        border: 'none'
      },
      button: {
        color: 'gray',
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        '&:hover': {
            color: '#000000',
            fontWeight: 1000
        }
      },
      icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
      },
      active: {
        color: '#000000',
        backgroundColor: '#bdbdbd',
        fontWeight: 'bold',
        '& $icon': {
          color: 'red'
        }
      }
    });
