export default theme => ({
    pageHeader: {
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    tableActionsBar: {
        padding: '0px 40px 30px 40px'
      },
  });
