import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';



import { useTranslation } from 'react-i18next';

import logoMnu from './../../../assets/imgs/logo-mnu.svg';
import styles from './styles';

/* Material-UI */
import {
  withStyles,
  FormControl,
  TextField,
  Grid,
  Typography,
  ThemeProvider,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Alert from '@material-ui/lab/Alert';



/* Components */
import SubmitButton from '../../../components/SubmitButton';
import AccountDialog from '../../../components/dialogs/AccountDialog';
import PopupAuth from '../../../components/dialogs/PopupInfo';


import * as STATUS_CODE from '../../../constants/httpCode';
import * as authService from './../../../services/AuthService/auth.service';

import authTheme from './../../../theme/authTheme';



const ForgotPassword = ({
  classes
}) => {

  const history = useHistory();
  const [openPopup, setOpenPopup] = useState(false);
  const [statusPopup, setStatusPopup] = useState("");
  const [openAccountDialog, setAccountDialog] = useState(false);
  const [multipleAccount, setMultipleAccount] = useState([]);

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t('invalid email'))
        .required(t('required field'))
    }),
    onSubmit: async (values) => {
      const dataPost = {
        username: values.email,
      }
      try {
        const response = await authService.resetPassword(dataPost);
        switch (response.data.statusCode) {
          case STATUS_CODE.SUCCESSFULLY_LOGIN: 
            setStatusPopup('SUCCCESS_FORGOT_PASSWORD');
            setOpenPopup(true);
            return null;
          
          case STATUS_CODE.MULTIPLE_TENANT:
            setMultipleAccount([...response.data.tenant]);
            setAccountDialog(true);
            return null

          default:
            break;
        }
      } catch (error) {
        setStatusPopup('ERROR_FORGOT_PASSWORD');
        setOpenPopup(true);
      }
    }
  })

  const resetPassword = async (dataPost) => {
    try {
      const response = await authService.resetPassword(dataPost);
      switch (response.data.statusCode) {
        case STATUS_CODE.SUCCESSFULLY_LOGIN: 
          setStatusPopup('SUCCCESS_FORGOT_PASSWORD');
          setOpenPopup(true);
          return null;
      
        case STATUS_CODE.MULTIPLE_TENANT:
          setMultipleAccount([...response.data.tenant]);
          setAccountDialog(true);
          return null

        default:
          break;
      }
    } catch (error) {
      setStatusPopup('ERROR_FORGOT_PASSWORD');
      setOpenPopup(true);
    }
  }

  const chooseAccountHandler = (account) => {
    setAccountDialog(false);
    const accountId = account.id;
    const dataPost = {
      username: formik.values.email,
      tenantId: accountId
    }
    resetPassword(dataPost)
  }

  const closePopupAuthHandler = () => {
    if (statusPopup === 'SUCCCESS_FORGOT_PASSWORD') {
      setStatusPopup('');
      setOpenPopup(false);
      history.push('/auth/login');
    } else {
      setStatusPopup('');
      setOpenPopup(false);
    }
  }


  const accountDialogRender = openAccountDialog ?
    <AccountDialog
      open={openAccountDialog}
      disableBackdropClick
      email={formik.values.email}
      tenants={multipleAccount}
      accountSeleted={chooseAccountHandler}
      closeDialog={() => setAccountDialog(false)} />
    : null;

  
  
  const popup = 
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={openPopup}>
      <DialogContent>
        <PopupAuth
          type={statusPopup}
          close={closePopupAuthHandler}
        />
      </DialogContent>
    </Dialog>  



  
  return (
    <>
      <ThemeProvider theme={authTheme}>
      <div className={classes.root}>
        <Grid className={classes.gridContainer}
          container
          justify='center'
          alignItems='center'
          alignContent='center'
        >
          <form onSubmit={formik.handleSubmit} className={classes.form} >

              {!!formik.errors.forgotPassword && (
                <Alert
                  className={classes.alertError}
                  variant="filled"
                  severity="error">{formik.errors.forgotPassword}</Alert>
              )}

            <div className={classes.boxLogoCyc}>
              <img width={117}
                height={44}
                  alt="Logo" src={logoMnu} />
              </div>
              <div className={classes.boxTitleAndBack}>
                <Typography className={classes.title} variant="h1">
                    {t('recover password')}
                </Typography>
                <div className={classes.boxBack} onClick={() => history.push('/auth/login')}>
                  <NavigateBeforeIcon style={{ color: '#D0212A' }} />
                  <p>Voltar</p>
                </div>
              </div>
          
      
            <FormControl
              fullWidth
              margin='normal'
            >
              <TextField
                id="email"
                  label={t('register email')}
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.email && formik.touched.email}
                helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
              />
            </FormControl>
              <div className={classes.boxButton}>
                <SubmitButton
                  className={classes.recoverPwdButton}
                  type="submit"
                  variant="contained"
                  loadingColor={"#FFFFFF"}
                  isSubmitting={formik.isSubmitting}
                >
                  {t('recover password')}
                </SubmitButton>
              </div>
          </form>
        </Grid>
        </div>
      </ThemeProvider>  
      {accountDialogRender}
      {popup}
    </>
  )
}

ForgotPassword.propTypes = {
  classes: PropTypes.object
}

export default (
  withStyles(styles)
)(ForgotPassword)
