import React, { useState } from 'react';
import { compose } from 'recompose';
import styles from './styles';
import { PropTypes } from "prop-types";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

/* Material-UI */
import {
  withStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  IconButton,
  Input,
  InputLabel
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const ChallengeAccountDialog = ({
  classes,
  closeDialog,
  save
}) => {

  const { t } = useTranslation();
  
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)


  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      password: '',
      passwordConfirm: ''
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required(t('required field'))
        .matches(
          /^.*(?=.{8,}).*$/,
         t('must contain 8 Characters')
       ),
      passwordConfirm: Yup.string()
        .required(t('required field'))
        .oneOf([Yup.ref('password'), null], t('passwords does not match'))

    }),
    onSubmit: async (values) => {
      save(values.password);
    }
  })

    
  /* Close the dialog */
  const handleClose = () => {
    closeDialog();
  };

  const handleClickShowPassword = () => {
    setShowPassword(previous => !previous)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(previous => !previous)
  };

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };



  return (
      <Dialog open={true}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth>
            <DialogTitle id="form-dialog-title">
              {t('change password')}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <DialogContentText>
                  <strong>{t('you must change your password')}.</strong>
                      <br/>
                  <strong>{t('please enter and confirm new password')}.</strong>
              </DialogContentText>
              <form className={classes.form} onSubmit={formik.handleSubmit} noValidate autoComplete="off">
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="standard-adornment-password">{t('password')}</InputLabel>
                      <Input className={classes.inputField}
                        autoFocus
                        margin="dense"
                        fullWidth
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.password && formik.touched.password}
                        helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  <FormControl fullWidth={true}>
                      <InputLabel htmlFor="standard-adornment-password">{t('password confirm')}</InputLabel>
                      <Input
                        className={classes.inputField}
                        margin="dense"
                        fullWidth
                        id="passwordConfirm"
                        type={showPasswordConfirm ? 'text' : 'password'}
                        value={formik.values.passwordConfirm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.passwordConfirm && formik.touched.passwordConfirm}
                        helperText={(formik.errors.passwordConfirm && formik.touched.passwordConfirm) && formik.errors.passwordConfirm}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPasswordConfirm}
                              onMouseDown={handleMouseDownPasswordConfirm}
                            >
                              {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
            
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        {t('cancel')}
                      </Button>
                      <Button
                        disabled={!formik.isValid}
                        type="submit"
                        color="primary">
                        {t('save')}
                      </Button>
                    </DialogActions>
              </form>
          </DialogContent>
      </Dialog>
)}

ChallengeAccountDialog.propTypes = {
  classes: PropTypes.object,
  closeDialog: PropTypes.func,
  save: PropTypes.func
}


export default compose(
  withStyles(styles)
)(ChallengeAccountDialog);