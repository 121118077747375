import React, { useState } from 'react';
import clsx from 'clsx';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './styles';

/* Material - UI */
import { withStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import Topbar from '../Topbar';
import Sidebar from '../Sidebar';
import Footer from '../Footer';





const Main = ({
  classes,
  children,
  user
}) => {

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  }); 
    
  const [openSidebar, setOpenSidebar] = useState(false);

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const onClickLink = () => {
    if (!isDesktop) {
      setOpenSidebar(false);
    } 
  }


  return(
          <div className={clsx({
            [classes.root]: true,
            [classes.rootPadding]: user.isAuthenticated,
            [classes.shiftContent]: (user.isAuthenticated && isDesktop)
            })} >
            {user.isAuthenticated ? <Topbar onSidebarOpen={handleSidebarOpen}/> : null}
            {user.isAuthenticated ?
              <Sidebar
                variant={isDesktop ? 'persistent' : 'temporary'}
                user={user}
                open={shouldOpenSidebar}
                onClose={handleSidebarClose}
                onClickLink={onClickLink}/>
              : null
            }
          <main  className={clsx({
            [classes.main]: true,  
            [classes.padding]: user.isAuthenticated
            })} >
            {children}
            {user.isAuthenticated ? <Footer /> : null}
         </main>
      </div>
  );
}

Main.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any,
  user: PropTypes.object
};


export default compose(
  withStyles(styles)
)(Main);