import axiosInstance from '../../axios';
import axios from 'axios';


const MODULE_ID = 'product-1';
const ASSETS = process.env.REACT_APP_ASSETS
const RESOURCE = process.env.REACT_APP_RESOURCE
const LIST = process.env.REACT_APP_RESOURCE_LIST
const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL



/* Get all asset */
export const   getAsset = async (params)  => 
        await axiosInstance.get(`${ASSETS}/${MODULE_ID}/${RESOURCE}/${LIST}`, {params: params});

/* Delete an asset */
export const deleteAsset  = async (assetId) => 
        await axiosInstance.delete(`${ASSETS}/${MODULE_ID}/${RESOURCE}/${assetId}`);
 
    
/* Get a url to upload file */
export const getUploadFileUrl = async (fileInfo) => 
        await axiosInstance.get(`${ASSETS}/${MODULE_ID}/${UPLOAD_URL}`, { params: { ...fileInfo }});
 

/* Upload file */
export const uploadFile = async (url, file, progressHandler) => {
    const config = { onUploadProgress: progressEvent => progressHandler(progressEvent)}
    return await axios.put(url, file, config);
}
 
/* Get all asset */
export const getImage = async (params) =>
        await axiosInstance.post(`${ASSETS}/${MODULE_ID}/download-url`, params);

/* Update an asset */
export const updateAsset = async (key, postData) => 
    await axiosInstance.put(`${ASSETS}/${MODULE_ID}/${RESOURCE}/${key}`, postData);


