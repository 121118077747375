export default theme => ({
    root: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 3px #00000029',
        borderRadius: '3px',
        padding: '30px',
        margin: '20px 0 auto'
    },
    boxHeader: {
        marginBottom: '50px'
    },
    boxContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    formControl: {
        width: '100%'
    },
    dropzone: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px dashed #707070',
        height: '212px',
        margin: '20px auto 30px',
        textAlign: 'center',
        '& p:first-child': {
            fontSize: '18px',
            fontWeight: 'bold'
        },
        '& p:nth-child(2)': {
            fontSize: '14px',
            margin: '18px auto 24px'
        }
    },
    buttonUpload: {
        border: '1px solid #D0212A',
        marginBottom: '25px'
    },
    thumbsRoot: {
        '& p: first- child': {
            fontSize: '14px',
            marginBottom: '10px'
        }
    },
    thumsContainer: {
        
    },
    thumb: {
        display: 'inline-flex',
        position: 'relative',
        marginRight: '10px !important',
        '& .thumb-overlay': {
            opacity: 0,
            position: "absolute",
            display: 'flex',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)'
        },
        '&:hover': {
            '& .thumb-overlay': {
                opacity: 1,
            }
        }
    },
    boxButtom: {
        position: "absolute",
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        zIndex: '1000'
    },
    deleteButton: {
        backgroundColor: 'white',
        boxShadow: 'none',
        minWidth: 'auto',
        margin: 0,
        padding: '0px',
        border: '1px solid #707070',
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: 'none'
        }
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
        width: '100%',
        border: '1px solid #C4C4C4',
        borderRadius: '5px',
        '& img': {
            display: 'block',
            width: '99%',
            margin: 'auto',
            height: '100%',
            color: 'black',
            objectFit:'cover'
        },
        '& p': {
            color: 'black'
        }
    },
    dragableList: {
        '&::-webkit-scrollbar': {
            height: '7px',
            borderRadius: '20px'
        },
        '&::-webkit-scrollbar-track': {
            background: '#D6D6D6 0% 0 % no - repeat padding-box',
            borderRadius: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#606060 0 % 0 % no - repeat padding-box',
            borderRadius: '20px'
        }
    },
    gridActionButton: {
        marginTop: '40px'
    },
    boxProgress: {
        position: 'absolute',
        width: '166px',
        height: '8px'
    },
    infoBoxInfoGenerating: {
        display: 'flex',
        justifyContent: 'center',
        '& p': {
            position: 'absolute',
            marginTop: '10px'
        }
    },
    sizeInfo: {
        textAlign: 'center',
        fontSize: '11px',
        color: '#404040',
        letterSpacing: '0.37px',
        lineHeight: '13px',
    },
    boxError: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px 29px',
        alignItems: 'center',
        textAlign: 'center',
        '& .title': {
            fontSize: '21px',
            lineHeight: '25px',
            letterSpacing: '0.71px',
            textTransform: 'uppercase',
            margin: '26px 0',
            fontWeight: 'bold'
        },
        '& .info': {
            fontSize: '16px',
            lineHeight: '29px',
            letterSpacing: '0.54px',
            maxWidth: '410px',
            '& span': {
                fontWeight: 'bold',
                color: '#8C161E',
            }
        }
    },
    errorIcon: {
        fontSize: '62px',
        color: '#C40303'
    },
    buttonClose: {
        color: '#8C161E',
        fontSize: '16px',
        lineHeight: '29px',
        letterSpacing: '0.54px',
        cursor: 'pointer',
        marginTop: '28px'
    },
    dialogContentRotateImage: {
        padding: 0
    },
    checkBoxEnableImage: {
        padding: '2px',
        '&.Mui-checked': {
            color: 'rgb(208,33,42, 1) !important'
        }  
    },
    pwaMessage: {
        fontSize: '12px'
    }
})