import React from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';



/* Material-UI */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';



const AlertDialog = ({
  open,
  title,
  info,
  disagree,
  agree,
  t
}) => {
    return (
        <Dialog
            onClose={disagree}
            open={open}
             disableBackdropClick={true}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {info}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={disagree} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={agree} color="primary" autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    );

}
export default compose(
  withTranslation()
)(AlertDialog);
