import React, {forwardRef} from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { PropTypes } from "prop-types";
import styles from './styles';


/* Material-UI */
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { Button, colors } from '@material-ui/core';


// eslint-disable-next-line react/display-name
const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <NavLink {...props} key="fdhfhdfh"/>
  </div>
));

      


const ListItemLink = ({
  classes,
  icon,
  title,
  link,
  productId,
  productName,
  onClickLink
}) => {

  const { t } = useTranslation();

   return (
        <ListItem
          className={classes.item}
          disableGutters
          key={title}>
       <Button
            onClick={onClickLink}
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={{
              pathname: `/${productId}${link}`,
              state: {
                productName: productName
              }
            }}>          
             <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={t(title)} />
          </Button>
        </ListItem>
   )
}

ListItemLink.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  productId: PropTypes.string,
  productName: PropTypes.string
}


export default compose(
  withStyles(styles)
)(ListItemLink);