import React, { useEffect, useReducer, useContext } from "react";
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { PropTypes } from "prop-types";
import moment from 'moment';

/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

/* Components */
import UserForm from  './components/UserForm';
import TableData from './../../components/TableData';
import SwitchButton from './../../components/SwitchButton';

/* Services */
import {useAlertDialog} from '../../services/AlertDialogService/alertDialog.service.js';
import * as userService from '../../services/UserService/user.service';
import * as roleService from '../../services/roleService/role.service.js';


/* Contexts */
import {LayoutContext} from '../../context/LayoutContext';

/* Constants */
import * as actionsType from "../../actions/actionsType";




const initialState = {
    users: [],
    dataTable: [],
    roles: [
        { id: 'ADMIN', name: 'Administration' },
        { id: 'USER', name: 'User' }
    ],
    formOpen: false,
    updatingUser: false,
    user: null
}

/* REDUCER */
const reducer = (state, action) => {
    switch (action.type) {
        case "SET_USERS":
            return {
                ...state,
                users: action.payload,
            }
        case "SET_DATA_TABLE": {
            return {
                ...state,
                dataTable: action.payload
            }
        }
        case "SET_ROLES":
            return {
                ...state
                //roles: action.payload
            }
        case "SET_UPDATING_ASSET":
            return {
                ...state,
                updatingUser: !state.updatingUser
            }
        case "SET_FORM":
            return {
                ...state,
                formOpen: !state.formOpen
            }
        case "SET_USER":
            return {
                ...state,
                user: action.payload
            }
        default:
            return state;
    }
}


const UserManagement = ({
    classes
}) => {

    const { t } = useTranslation(); 
    const {layoutDispatch} = useContext(LayoutContext); 
    const alerDialog = useAlertDialog();
    const [state, dispatch] = useReducer(reducer, initialState);

    const columns = [
        { title: t('name'), field: 'name' },
        { title: t('role'), field: 'role' },
        { title: t('email'), field: 'email' },
        { title: t('date created'), field: 'dateCreated' },
        { title: t('status'), field: 'status' },
        { title: t('enabled'), field: 'enabled' }
    ]

    const actions = [
        {
            icon: 'edit',
            tooltip: t('edit'),
            onClick: (event, rowData) => { editUserHandler(rowData) }
        }
    ];

    useEffect(() => {
        getAllUsers();
    }, []);


 
    const getAllUsers = async () => {
        layoutDispatch({ type: 'LOADING', data: { loading: true } });
        try {
            const response = await userService.getUsers();
            layoutDispatch({
                type: 'LOADING',
                data: { loading: false }
            });
            dispatch({
                type: "SET_USERS",
                payload: response.data.data
            })
            dispatch({
                type: "SET_DATA_TABLE",
                payload: setDataTable(response.data.data)
            })
        } catch (error) {
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        }
    };


    const enableUserHandler = async (username, enable) => {
            const string = enable ? t('enable') : t('disable');
            const dataAlert = {
                title: t('user'),
                info: t(`are you sure of ${string} this user?`)
            }
            alerDialog({
                ...dataAlert
            })
                .then(async () => {
                    try {
                        layoutDispatch({ type: actionsType.LOADING, data: { loading: true } });
                        const postData = {
                            userStatus: enable,
                            username: username
                        };
                        await userService.changeStatus(postData);
                        layoutDispatch({ type: actionsType.LOADING, data: { loading: false } });
                        getAllUsers();
                    } catch (error) {
                        layoutDispatch({ type: actionsType.LOADING, data: { loading: false } });
                    }
                })
                .catch(() => { })
        }


    const setDataTable = (users) => {
            return users.map(user => {
                let usr = {};
                user.Attributes.forEach(atribube => {
                    const keys = Object.keys(atribube);
                    usr[atribube[keys[0]]] = atribube[keys[1]];
                })
                usr['id'] = user.Username;
                usr['enabled'] = user.Enabled;
                usr['dateCreated'] = user.UserCreateDate
                usr['status'] = user.UserStatus
                return {
                    id: usr.id,
                    name: usr.name,
                    role: usr['custom:roleId'],
                    email: usr.email,
                    dateCreated: `${moment(user.UserCreateDate).format('ll')} | ${moment(user.UserCreateDate).format('LT')}`,
                    status: usr.status,
                    enabled: <SwitchButton checked={usr.enabled} onChange={(enable) => enableUserHandler(usr.id, enable)} />
                };
            })
        }
   
    /* Handler add user click */
    const addHandler = async () => {
        dispatch({ type: "SET_USER", payload: null });
        layoutDispatch({ type: actionsType.LOADING, data: { loading: true } });
        try {
            const response = await roleService.getRoles();
            dispatch({
                type: "SET_ROLES",
                payload: response.data
            })
            dispatch({ type: "SET_FORM" })
            layoutDispatch({ type: actionsType.LOADING, data: { loading: false } });
        } catch (error) {
            layoutDispatch({ type: actionsType.LOADING, data: { loading: false } });
        }
    }

    /* Handler edit user click*/
    const editUserHandler = async (user) => {
        try {
            layoutDispatch({ type: actionsType.LOADING, data: { loading: true } });
            await roleService.getRoles();
            layoutDispatch({ type: actionsType.LOADING, data: { loading: false } });
            dispatch({
                type: "SET_USER",
                payload: user
            })
            dispatch({ type: "SET_FORM" })
        } catch (error) {
            layoutDispatch({ type: actionsType.LOADING, data: { loading: false } });
        }
    }

    /* Delete handler */
    const deleteUserHandler = async (user) => {
        const dataAlert = {
        title: t('delete user'),
        info: t('are you sure of delele this user')
        }
        alerDialog({
        ...dataAlert
        })
        .then(() => { })
        .catch(() => {})
    }

    const saveUser = async (data) => {
        try {
            dispatch({ type: "SET_FORM" })
            layoutDispatch({ type: actionsType.LOADING, data: { loading: true } });
            await userService.createUser(data);
            getAllUsers();
            layoutDispatch({ type: actionsType.LOADING, data: { loading: false } });
        } catch (error) {
            layoutDispatch({ type: actionsType.LOADING, data: { loading: false } });
        }
    }

    const updateUserHandler = async (data) => { }


    const usersTableRender = state.dataTable ?
        <Box className={classes.boxTable}>
            <TableData
                title={t('list of users')}
                columns={columns}
                data={state.dataTable}
                actions={actions} />
        </Box>
        : null; 
    
    const formRender = 
        <Dialog
            open={state.formOpen}
            disableBackdropClick
            onClose={() => dispatch({ type: "SET_FORM" })}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <UserForm
                    roles={state.roles}
                    updating={state.updatingUser}
                    user={state.user}
                    onSave={(data) => saveUser(data)}
                    onUpdate={(data) => updateUserHandler(data)}
                    onClose={() => dispatch({ type: "SET_FORM" })}
                />
            </DialogContent>
        </Dialog>


    return ( 
        <>
            <Container maxWidth={false}>
            <Typography variant="h3">
                {t('user Management')}
            </Typography>    
            <div className={classes.pageHeader}>
                <Button
                    variant="contained" 
                    color="primary" 
                    startIcon={<AddCircleIcon />}
                    onClick={addHandler}>
                     {t('add user')}
                </Button>
                </div>
                {usersTableRender}
            </Container>      

            {formRender}

        </>    
    );
}


UserForm.propTypes = {
    classes: PropTypes.object
}


 
export default compose(
    withStyles(styles)
)(UserManagement);



const getRoleOptions = (roles) => {
    return roles.map(role => getRole(role.id))
}

const getRole = (roleId) => {
    switch (roleId) {
        case 'ADMIN':
            return {
                value: 'ADMIN',
                displayValue: 'Administrator'
            }
        case 'USER':
            return {
                value: 'USER',
                displayValue: 'User'
            }
        default:
            return null;
    }
}
