import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './styles';


/* Material-UI */
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

/* Components */
import ListItemMenu from './components/ListItemMenu';






const SidbarNav = ({
  classes,
  user,
  onClickLink
}) => {


  const { products } = user.data.role;


    return (
        <List component="nav"
           aria-labelledby="nested-list-subheader"
           className={classes.root}>
              {products.map(product => (
                <ListItemMenu key={product.id} product={product} onClickLink={onClickLink}/>
        ))}
      </List>
    )
}

SidbarNav.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object
};


export default compose(
  withStyles(styles)
)(SidbarNav);
