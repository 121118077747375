import React, { useReducer, useEffect, useContext} from 'react';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import { PropTypes } from "prop-types";
import moment from 'moment';

/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

/* Components */
import TableData from './../../components/TableData';


/* Services */
import * as roleService from '../../services/roleService/role.service.js';

/* Contexts */
import { LayoutContext } from '../../context/LayoutContext';




const initialState = {
    roles: [],
    dataTable: []
}


/* REDUCER */
const reducer = (state, action) => {
    switch (action.type) {
        case "SET_ROLES":
            return {
                ...state,
                roles: action.payload,
            }
        case "SET_DATA_TABLE": {
            return {
                ...state,
                dataTable: action.payload
            }
        }
        default:
            return state;
    }
}


const RoleMagement = ({
    classes
}) => {

    const { t } = useTranslation(); 
    const [state, dispatch] = useReducer(reducer, initialState);
    const { layoutDispatch } = useContext(LayoutContext); 


    const columns = [
        { title: t('name'), field: 'name' },
        { title: t('ID'), field: 'id' },
        { title: t('products'), field: 'products' }
    ]



    useEffect(() => {
        const getAllRoles = async () => {
            layoutDispatch({ type: 'LOADING', data: { loading: true } });
            try {
                const response = await roleService.getRoles();
                layoutDispatch({
                    type: 'LOADING',
                    data: { loading: false }
                });
                dispatch({
                    type: "SET_ROLES",
                    payload: response.data.data
                })
                dispatch({
                    type: "SET_DATA_TABLE",
                    payload: setDataTable(response.data.data)
                })
            } catch (error) {
                layoutDispatch({ type: 'LOADING', data: { loading: false } });
            }
        };
        getAllRoles();
    }, [layoutDispatch]);


    const setDataTable = (roles) => {
        return roles.map(role => {
            return {
                id: role.id,
                name: role.name,
                products: role.products.map(prod => prod.name).join()
            }
        })
    }


    const rolesTableRender = state.dataTable ?
        <Box className={classes.boxTable}>
            <TableData
                title={t('list roles')}
                columns={columns}
                data={state.dataTable} />
        </Box>
        : null; 


    return (
            <Container maxWidth={false}>
                <Typography variant="h3">
                    {t('role management')}
                </Typography>
                <div className={classes.pageHeader}>
                    <span></span>
                </div>
                {rolesTableRender}
            </Container>
    );
}

export default compose(
    withStyles(styles)
)(RoleMagement);

