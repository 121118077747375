export default theme => ({
    root: {
        margin: '20px 0',
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    ementaContainer: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 3px #00000029',
        borderRadius: '3px',
        padding: '30px',
        margin: '20px 0 auto',
    },
    ementaBoxHeader: {
        marginBottom: '50px',
        color: '#707070',
        fontSize: '16px'
    },
    ementaBoxContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    boxInfo: {
        fontSize: '14px',
        fontLineHeight: '16px',
        letterSpacing: '0.5px',
        marginBottom: '20px',
        display: 'flex',
        '& p:first-child': {
            color: '#000000',
            fontWeight: 'bold'
        },
        '& p:nth-child(2)': {
            color: '#404040',
            marginLeft:'5px'
        }
    },
    thumbsContainer: {
        marginBottom: '30px',
        '& p:first-child': {
            marginBottom: '10px',
            fontSize: '14px',
            fontLineHeight: '16px',
            letterSpacing: '0.5px',
            fontWeight: 'bold'
        }
    },
    thumbslist: {
        display: 'flex',
        overflowY: 'auto',
        maxWidth: '440px',
        flexWrap: 'wrap',
        '&::-webkit-scrollbar': {
            height: '7px',
            borderRadius: '20px'
        },
        '&::-webkit-scrollbar-track': {
            background: '#D6D6D6 0% 0 % no - repeat padding-box',
            borderRadius: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#606060 0 % 0 % no - repeat padding-box',
            borderRadius: '20px'
        }
    },
    thumbInner: {
        display: 'flex',
        overflow: 'hidden',
        border: '1px solid #C4C4C4',
        borderRadius: '5px',
        width: '100px',
        height: '100px',
        minWidth: '100px',
        marginRight: '10px',
        marginBottom: '10px',
        '& img': {
            display: 'block',
            width: '100%',
            margin: 'auto',
            
            height: '100%',
            objectFit: 'cover'
        }
    },
    qrcodeContainer: {
        marginBottom: '34px',
        '& p:first-child': {
            marginBottom: '10px',
            fontSize: '14px',
            fontLineHeight: '16px',
            letterSpacing: '0.5px',
            fontWeight:'bold'
        }
    },
    qrcodeBox: {
        padding: '20px',
        border: '1px dashed #707070',
        borderRadius: '30px',
        width: 'max-content'
    },
    downloadLink: {
        color: 'white'
    }
});
