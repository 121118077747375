export default theme => ({
    root: {
      height: '100%',
      display: 'flex'
    },
    rootPadding: {
      paddingTop: 74,
    },
    shiftContent: {
      paddingLeft: 280
    },
  main: {
    width: '100%'
  },
  padding:{
    padding: '32px 5px'
  }
  });
  