import React, { useReducer, createContext } from "react";


export const Context = createContext();



const initialState = {
    loading: false,
    snakbar: {
        show: false,
        message: '',
        severiry: 'success'
    },
    user: {
        isAuthenticated: false,
        data: null,
        authChecked: false
    },
    openMenuUser: false
}


export const reducer = (state, action) => {
    switch (action.type) {
        case 'AUTH_LOGIN':
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            }
        case 'AUTH_CHECKED':
            return {
                ...state,
                user: {
                    ...state.user,
                    authChecked: action.payload
                }
            }
        case 'AUTH_LOGOUT':
            return {
                ...state,
                user: {
                    data: {},
                    authChecked: true,
                    isAuthenticated: false
                }
            }
        default:
            return state;
    }
}


const ContextProvider = (props) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };

    return (
        <Context.Provider value={value}>
            {props.children}
        </Context.Provider>
    )
}
export default ContextProvider;

export const withContext = WrappedComponent => props => (
    <Context.Consumer>
        {value => <WrappedComponent {...props} context={value} />}
    </Context.Consumer>
);

