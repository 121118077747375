import React from 'react';
import clsx from 'clsx';
import { compose } from 'recompose';
import styles from './styles';

/* Material - UI */
import { withStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';


const Footer = ({
  classes
}) => {


  return (
    <div
      className={clsx(classes.root)}
    >
      <Typography variant="body1">
        &copy;{' '}
        <Link
          component="a"
          href="https://www.cycloid.pt/"
          target="_blank"
        >
          CYCLOID Technology and Consulting, Lda
        </Link>
        . 2020
      </Typography>
      <Typography variant="caption">
        <strong>We are stronger together</strong>
      </Typography>
    </div>
  );
};



export default compose(
  withStyles(styles)
)(Footer);