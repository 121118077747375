import axios from '../../axios';


const ADMIN =  process.env.REACT_APP_ADMIN;
const USER_LIST = process.env.REACT_APP_USER_LIST;
const USER_CREATE = process.env.REACT_APP_USER_CREATE;
const USER_STATUS = process.env.REACT_APP_USER_STATUS;


/* Get all users from API */
export const getUsers = async () => await axios.get(`${ADMIN}/${USER_LIST}`);




/* Create a  user */
export const createUser = async (postData) => {
    try{
        await axios.post(`${ADMIN}/${USER_CREATE}`, postData);
        return true;
    }
    catch (error) {
        return  Promise.reject({eroorCode: 1});
    }
}

/* Delete a  user */
export const deleteUser = async (userId) => {
    return false;
}

/* Change a  user stratus */
export const changeStatus = async (postData) =>
    await axios.post(`${ADMIN}/${USER_STATUS}`, postData)