export default theme => ({
    root: {
        flexGrow: 1
    },
    boxHeader: {
      margin: "10px auto",
      padding: '20px 10px',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    cardInfo: {

    }
  });
