import React from 'react';

/* Material-UI */
import {
    withStyles,
    LinearProgress,
} from '@material-ui/core';

const ProgressLinear = () => {
  return (
      <LinearQueryProgress variant="query"/>
    )
}

export default ProgressLinear

const LinearQueryProgress = withStyles({
    root: {
        height: '100%',
        backgroundColor: 'rgba(208, 33, 42, 0.2)',
        borderRadius: 20,
        padding: '2px'
    },
    bar: {
        borderRadius: 20,
        background: 'rgba(208, 33, 42, 1)'
    },
})(LinearProgress);

