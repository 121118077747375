export default theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    item: {
        display: 'flex',
        paddingTop: 12,
        paddingBottom: 12,
      }
  });