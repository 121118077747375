import * as actionsType from "../actions/actionsType";


export const menuReducer = (state, action) => {
    switch (action.type) {
        case actionsType.OPEN_MENU:
            return {...state,  ...action.data}

        case actionsType.CLOSE_MENU:
            return {...state,  ...action.data}    

        default:
            return state;
    }
}

