import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';


/* Material-UI */
import { withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';




const SwitchButton = ({
    classes,
    checked,
    onChange
}) => {


    const handleChange = (event) => {
        onChange(event.target.checked)
    }

    return (
        <Switch
            checked={checked}
            classes={classes}
            name="switchButton"
            onChange={handleChange}/>
    )
}

SwitchButton.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(SwitchButton);
