
import axios from 'axios';


import * as HTTP_CODE from './../constants/httpCode';

import { getIdToken, getRefreshToken, getExpirationDate, clearAllDataSession} from './../services/storage';
import { useHistory } from 'react-router-dom';



const REFRESH_TOKEN =  process.env.REACT_APP_AUTH_IDENTITY_REFRESH_TOKEN;
const BASE_URL = process.env.REACT_APP_DOMAIN;
const STAGE = process.env.REACT_APP_STAGE;

let refreshingToken = false;  // Indicate if are refreshing token processa
let failedQueue = [];         // Queue request failed while refreshing


const axiosInstance = axios.create({
 baseURL: BASE_URL
});



/* Request interceptor */
axiosInstance.interceptors.request.use(async request => {
   


   const token = await getIdToken();
  if (token) {
    const dateExpirationToken = await getExpirationDate();
    const now = new Date();
    if (now.getTime() >= dateExpirationToken.getTime()) {
      clearAllDataSession();
      const history = useHistory();
      history.push('auth/login')
      return
    }
      request.headers['Authorization'] = token;
    }
    request.url = `${request.baseURL}/${STAGE}${request.url}`
    return request;
  },
  (error) =>  {
    return Promise.reject(error);
  });


  /* Response interceptor */
  axiosInstance.interceptors.response.use(
    (response) => {
    return response;
  }, 
    async (error) => {
     const originalRequest = error.config;
     const statusCode = error.statusCode;
     switch (statusCode) {
       case 403: {
         if (refreshingToken) {
           return new Promise((resolve, reject) => {
             failedQueue.push({ resolve, reject })
           }).then(token => {
             originalRequest.headers['Authorization'] = token;
             return axios(originalRequest);
           }).catch(err => {
             return Promise.reject(err);
           })
         }
         originalRequest._retry = true;
         refreshingToken = true;
         const token = await getRefreshToken();
         return new Promise((resolve, reject) => {
           const data = { refreshToken: token };
           axios.post(`${BASE_URL}/${REFRESH_TOKEN}`, data).then(resp => {
             //console.log('Refreshed token', resp);
             /*
               Save refreshed token
             */
             processQueue(null, data.token);
             resolve(axios(originalRequest));
           })
             .catch(err => {
               processQueue(err, null);
               reject(err);
             })
             .then(() => { refreshingToken = false });
         })
       }
       case 401: {
         // Quicak
         break
         }
       default:
         break
     }
    return Promise.reject(error);
  });


  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    })
    failedQueue = [];
  }
export default axiosInstance;