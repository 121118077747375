import React, {useState} from 'react';
import { compose } from 'recompose';


/* Material-UI */
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const SnackbarDialog = ({
  show,
  severiry,
  message,
  snackbarHandleClose
}) => {

    const [horizontal] = useState('center');
    const [vertical] = useState('top');

    const handleClose = () => {
        snackbarHandleClose();
    }

    return (
        <Snackbar
          anchorOrigin={ { horizontal, vertical}}
          key={`${vertical},${horizontal}`}
          open={show}
          autoHideDuration={3000} 
          onClose={handleClose}>
            <MuiAlert elevation={6} variant="filled"  onClose={handleClose} severity={severiry}>
               {message}
            </MuiAlert>
      </Snackbar>
    );
}

export default compose()(SnackbarDialog);