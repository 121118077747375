import React, { useEffect, useReducer, useContext, useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from "prop-types";
import { compose } from 'recompose';
import moment from 'moment';
import styles from './styles';
import QRCode from 'qrcode.react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


import { PDFDownloadLink } from '@react-pdf/renderer';
import DocumentPdf from './components/DocumentPdf';




/* Material-UI */
import {
  withStyles,
  Button, 
  Typography,
  Container,
  Grid,
} from '@material-ui/core'

//import AddCircleIcon from '@material-ui/icons/AddCircle';

/* Components */
import FormEmenta from './components/FormEmenta';

/* Contexts */
import { LayoutContext } from '../../context/LayoutContext';

/* Services */
import * as ementaService from '../../services/ementaService/ementa.service';




const initialState = {
  menus: [],
  menu: {},
  updatingMenu: false,
  creatingMenu: false,
  editingMenu: false,
  assetsCreated: [],
  renderPdf: false
}

/* REDUCER */
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_MENUS":
      return {
        ...state,
        menus: action.payload
      };
    case "SET_MENU":
      return {
        ...state,
        menu: action.payload
      }
    case "SET_UPDATING":
      return {
        ...state,
        updatingMenu: action.payload
      }
    case "SET_CREATING":
      return {
        ...state,
        creatingMenu: action.payload
      }
    case "SET_EDITING":
      return {
        ...state,
        editingMenu: action.payload
      }
    case "SET_ASSETS_CREATED":
      return {
        ...state,
        assetsCreated: [
          ...state.assetsCreated,
          action.payload
        ]
      }
    case "SET_RENDER_PDF":
      return {
        ...state,
        renderPdf: action.payload
      }
    default:
      return state;
  }
}


const Ementa = ({
  classes
}) => {
  const { t } = useTranslation();
  const { layoutDispatch } = useContext(LayoutContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { productId } = useParams();
  const location = useLocation();

  const { productName } = location.state
  
   const ref = useRef(null)
  const [canvas, setCanvas] = useState(null)








  useEffect(() => {
    ementaService.setModuleId(productId);
    const getAllMenu = async () => {
      layoutDispatch({ type: 'LOADING', data: { loading: true } });
      try {
        const response = await ementaService.getMenus();
        layoutDispatch({ type: 'LOADING', data: { loading: false }});
        dispatch({
          type: "SET_MENUS",
          payload: response.data.data
        })
      } catch (error) {
        layoutDispatch({ type: 'LOADING', data: { loading: false } });
      }
    };
    getAllMenu();
  }, [productId]);


  const getAllMenu = async (loading) => {
    layoutDispatch({ type: 'LOADING', data: { loading: loading } });
    try {
      const response = await ementaService.getMenus();
      layoutDispatch({type: 'LOADING', data: { loading: false }});
      dispatch({
        type: "SET_MENUS",
        payload: response.data.data
      })
    } catch (error) {
      layoutDispatch({ type: 'LOADING', data: { loading: false } });
    }
  };



  const createMenuHandler = async (data) => {
    dispatch({ type: "SET_CREATING", payload:true })
    const assets = [];
    //const postData = {
    //  title: data.name
   // }
  /* Create assets */
  /*  await Promise.all(
      data.assetsSk.map( async (sk) => {
        try {
          await ementaService.updateAsset(sk, postData)
        } catch (error) {
          dispatch({ type: "SET_CREATING", payload: false })
          return null;
        }
      })
    )
    */

  /* Confirm upload (Will update asset table) */ 
    try {
      const confirmResp = await ementaService.confirmUpload({ assets: data.files })
      const assetsConfirmed = confirmResp.data.data;
      assetsConfirmed.map((item, index) => {
        const asst = {
          id: item.id,
          type: item.type,
          created_by: item.created_by,
          bucket_key: item.bucket_key,
          title: data.name,
          enable: data.files[index].enable
        }
        assets.push(asst);
      })

    } catch (error) {
      // Error handler
    }

    dispatch({ type: "SET_ASSETS_CREATED", payload: assets });
    const menuAssets = assets.map(asset => {
      return {
        id: asset.id,
        type: asset.type,
        createdby: asset.created_by,
        bucket_key: asset.bucket_key,
        title: asset.title,
        enable: asset.enable
      }
    })

    const postDataEmenta = {
      name: data.name,
      content: {
        enable: true,
        assets: menuAssets
      } 
    }

    layoutDispatch({ type: 'LOADING', data: { loading: false } });
    await createEmenta(postDataEmenta);
  }

  const createEmenta = async data => {
    layoutDispatch({ type: 'LOADING', data: { loading: false } });
    try {
      await ementaService.createMenu(data);
      dispatch({ type: "SET_CREATING", payload: false })
      getAllMenu()
    } catch (error) {
      dispatch({ type: "SET_CREATING", payload: false })
    }
  }


  const updateMenuHandler = async (data) => {
    const assets = [];
    dispatch({ type: "SET_EDITING", payload: true })
   
    try {
      const filesToCreateAsset = data.files.filter(file => !file.hasAsset || file.edited);
      if (filesToCreateAsset.length > 0) {
        const confirmResp = await ementaService.confirmUpload({ assets: filesToCreateAsset })
        const assetsConfirmed = confirmResp.data.data;
        assetsConfirmed.map((item, index) => {
          const asst = {
            id: item.id,
            type: item.type,
            created_by: item.created_by,
            bucket_key: item.bucket_key,
            title: data.name,
            enable: data.files[index].enable
          }
          assets.push(asst);
        })
      }
    } catch (error) {
      // Error handler
    }

    let menuAssetUpdated = data.files.map(file => {
      if (file.hasAsset) {
        const asst = state.menu.content.assets.find(asst => asst.id === file.id)
        if (asst) {
          asst['enable'] = file.enable
        }
        return asst;
      } else {
        const assetCreated = assets.find(asst => asst.id === file.id)
        return assetCreated ? assetCreated : null;
      }
    })

    const postDataEmenta = {
      name: data.name,
      content: {
        enable: true,
        assets: menuAssetUpdated
      }
    }
  
    layoutDispatch({ type: 'LOADING', data: { loading: false } });
    await updateMenu(postDataEmenta)
  }

  const updateMenu = async (postData) => {
    //layoutDispatch({ type: 'LOADING', data: { loading: false } });
    try {
      const menuId = state.menu.menu_id;
      await ementaService.updateMenu(menuId, postData)
      getAllMenu(true)
      dispatch({ type: "SET_EDITING", payload: false })
      dispatch({ type: "SET_UPDATING", payload: false })
    } catch (error) {
      layoutDispatch({ type: 'LOADING', data: { loading: false } });
      dispatch({ type: "SET_EDITING", payload: false })
      dispatch({ type: "SET_UPDATING", payload: false })

    }
  }

  const editMenuHandler = async () => {
    layoutDispatch({ type: 'LOADING', data: { loading: true } });
    try {
      const menu = state.menus[0];
      const resp = await ementaService.getMenu(menu.menu_id);
      dispatch({ type: "SET_MENU", payload: resp.data })
      dispatch({ type: "SET_UPDATING", payload: true })
      layoutDispatch({ type: 'LOADING', data: { loading: false } });
      }
     catch (error) {
      layoutDispatch({ type: 'LOADING', data: { loading: false } });
    }
  }
  
  const cancelCreateHandler = () => {
    dispatch({ type: "SET_FORM", payload: false })
  }

  const cancelEditHandler = () => {
    dispatch({ type: "SET_UPDATING", payload: false })
  }


  const downloadQrcodeHandler = () => {    
    const qrCodeCanvas = document.getElementById("qrcodecanvas")
    const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.3);
    setCanvas(qrCodeDataUri)
    setTimeout(() => {
      const a_ref = ref.current.querySelector('a');
      a_ref.click()
    }, 1000);
  };






  const ementaRender =
    state.menus.length ?
    <div className={classes.ementaContainer}>
      <div className={classes.ementaBoxHeader}>
        {state.menus[0].name}
      </div>
      <div className={classes.ementaBoxContent}>
        <Container maxWidth="sm">
          <div className={classes.boxInfo}>
              <p>{t('name')}:</p>
              <p>{state.menus[0].name}</p>
          </div>
            <div className={classes.boxInfo}>
              <p>{t('update on')}:</p>
              <p> {moment(state.menus[0].update_on).format('L')} | {moment(state.menus[0].update_on).format('HH:mm')}</p>
          </div>
          <div className={classes.thumbsContainer}>
            <p>{t('menu images')}:</p>
              <div className={classes.thumbslist}>
                {state.menus[0].content.assets.map(asset => 
                
                  asset && asset.enable ? (
                    <div className={classes.thumbInner} key={asset.bucket_key}>
                      <img
                        src={asset.hasOwnProperty('url_compress') ? asset.url_compress : asset.url}
                        alt="thumb" />
                    </div>
                  ) : null
                )}
            </div>
          </div>
            <div className={classes.qrcodeContainer}>
              <p>{t('qrcode menu')}</p>
              <div className={classes.qrcodeBox}>
                <QRCode
                  id="qrcodecanvas"
                  value={state.menus[0].externalUrl}
                  size={146}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"H"}
                  renderAs={'canvas'}
                />
              </div>
          </div>
          <Grid container justify="flex-start" spacing={2} className={classes.gridActionButton}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => editMenuHandler()}>
                {t('edit')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                  onClick={() => downloadQrcodeHandler()}>
                  {t('download qrcode')}
                </Button>
                <div ref={ref}>
                  <PDFDownloadLink
                    id="PDFDownloadLink"
                    className={classes.downloadLink}
                    document={<DocumentPdf qrCodeCanvas={canvas} title={state.menus[0].name} description={t('qrcode pdf title')}/>}
                    fileName="MNU QRcode.pdf">
                  </PDFDownloadLink>
                </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      </div> : null
  

  return (
    <>
      <Container className={classes.root} maxWidth={false}>
        <Typography variant="h3">
          {t('menus management')}
        </Typography>
        
        {
          state.updatingMenu ? (
            <FormEmenta
              menu = {state.menu}
              updateMenu={state.updatingMenu}
              editing={state.editingMenu}
              productName= {productName}
              onUpdate={(data) => updateMenuHandler(data)}
              onCancel={() => cancelEditHandler()}
            />
          ) : 
            state.menus && state.menus.length === 0 ?
              <FormEmenta
                createMenu={!state.updatingMenu}
                creating={state.creatingMenu}
                productName={productName}
                onCreate={(data) => createMenuHandler(data)}
                onCancel={() => cancelCreateHandler()}
              /> :
              state.menus && state.menus.length === 1 ? ementaRender : null
        }
        

      </Container>

    
    </>
  );
}


Ementa.propTypes = {
  classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Ementa)

