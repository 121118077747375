import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { useTranslation } from 'react-i18next';


const Welcome = ({
    classes
}) => {

    const { t } = useTranslation();

  return (
    <>
      <div className={classes.root}>
         <h1>{t('welcome')}</h1>
         <p>{t('welcome info1')}</p>   
         <p>{t('welcome info2')}</p>   
      </div>
    </>
  )
}

Welcome.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Welcome)
