export default theme => ({
    root: {
        margin: '20px 0',
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    container: {
        flex: 1,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 3px #00000029',
        borderRadius: '3px',
        padding: '30px',
        margin: '20px 0 auto',
    },
    boxContent: {
        width: 'auto',
        display: 'flex',
        padding: '20px',
        justifyContent: 'center',

        // backgroundColor: 'purple'
    },
    formControl: {
        minWidth: '220px',
        // backgroundColor: 'purple'
    },

    boxSection: {
        width: '100%',
        height: 'auto',
        // backgroundColor: 'orange',
    },

    boxSectionTitle: {
        width: '100%',
        paddingBottom: '30px',
        fontWeight: 'normal',
        fontFamily: 'Roboto',
        fontSize: '25px',

        // backgroundColor: 'gray',
    },

    boxSectionContainer: {
        width: '100%',
        height: 'auto',
        display: 'flex',

        // backgroundColor: 'green',
    },

    boxSectionContainerColumn: {
        width: '100%',
        height: 'auto',
        // paddingRight: '50px',

        // backgroundColor: 'brown',
    },
    boxSectionContainerColumnRow: {
        width: '100%',
        height: 'auto',
        paddingTop: '10px',
        paddingBottom: '10px',

        // backgroundColor: 'pink',
    },

    columnRowLabel: {
        paddingBottom: '9px',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        fontSize: '16px',
    },

    columnRowValue: {
        width: '100%',
        marginTop: '5px',
        fontWeight: 'normal',
        fontFamily: 'Roboto',
        fontSize: '16px',
        opacity: '0.8',
        // backgroundColor: 'yellow'
    },
    columnRowValueEmpty: {
        width: '220px',
        height: '16px',
        marginTop: '5px',
        borderRadius: '8px',
        backgroundColor: '#777',
        animation: 'shimmer 2s infinite linear',
        background: 'linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)',
        backgroundSize: '1000px 100%'
    },



    actionContextContainer: {
        height: 'auto',
        display: 'flex',
        padding: '20px 0px',
        // backgroundColor: 'brown'
    },
    

    "@keyframes shimmer": {
        "0%": {
            backgroundPosition: '-1000px 0',
        },
        "100%": {
            backgroundPosition: '1000px 0',
        }    
    }

});


