import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';


import PrivateRoute from './PrivateRoutes';

/* Screens */
import Login from '../screens/Auth/Login';
import ForgotPassword from '../screens/Auth/ForgotPassword';
import ResetPassword from '../screens/Auth/ResetPassword';
import Welcome from '../screens/DefaultPage';
import AssetManagement from './../screens/Asset';
import UserManagement from './../screens/User';
import RoleManagement from './../screens/Role';
import Playlist from './../screens/Playlist';
import PlaylistManagement from './../screens/Playlist/components/PlaylistManagement';
import Ementa from './../screens/Ementa/';
import EmentaConfigurations from './../screens/Ementa/configurations';


const Routes = () => {
   return (
      <Switch>
            <Route
               path="/auth/login"
               exact
               component={Login}
            />
            <Route
               path="/auth/forgot-password"
               exact
               component={ForgotPassword}
            />
            <Route
               path="/auth/reset-password/confirm"
               exact
               component={ResetPassword}
            />
            <PrivateRoute 
               path="/:productId/users/user-management" 
               exact 
               component={UserManagement} 
            />
            <PrivateRoute
               path="/:productId/assets/asset-management" 
               exact 
               component={AssetManagement}
            />
            <PrivateRoute
               path="/:productId/roles/role-management" 
               exact 
               component={RoleManagement}
            />
            <PrivateRoute
               path="/:productId/playlist"
               exact
               component={Playlist} 
            />
            <PrivateRoute
               path="/playlist/:playlistId/management"
               exact
               component={PlaylistManagement} 
            />
            <PrivateRoute
               path="/:productId/ementa"
               exact
               component={Ementa}
            />
            <PrivateRoute
               path="/:productId/ementa/configurations"
               exact
               component={EmentaConfigurations}
            />
            <PrivateRoute
               path="/home"
               exact
               component={Welcome}
            />
            <Redirect 
                from="/" 
                to="/home" 
               exact
            />
            <Redirect 
               to="/"
            />
         </Switch>
    )
}

export default Routes;
