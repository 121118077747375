import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styles from './styles';

/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';



const AssetTransferList = ({
    classes,
    assets = [],
    playlist = {},
    seletedAssets = [],
    addAssetsHandler,
    removeButton,
    getSeleted,
    cancel
}) => {

   const { t } = useTranslation();

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
  
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

  
    useEffect(() => {
      const assetsPlaylist = playlist.content.map(cont => { return cont.assets });
      const assetsFiltered = assets.filter(asset => !assetsPlaylist.find(a => a.id === asset.id))
      assets = [... assetsFiltered];
      setLeft(assets);
      setRight(seletedAssets);
    }, [])




   

  
    const handleToggle = value => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(
        
      );
    };
  
    const numberOfChecked = items => intersection(checked, items).length;
  
    const handleToggleAll = items => () => {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    };
  
    const handleCheckedRight = () => {
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
      if (getSeleted) {
        getSeleted(right.concat(leftChecked));
      }
    };
  
    const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked));
      setRight(not(right, rightChecked));
      setChecked(not(checked, rightChecked));
      if (getSeleted) {
        getSeleted(not(right, rightChecked));
      }
      
    };
  
    const customList = (title, items) => (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.map(value => {
            const labelId = `transfer-list-all-item-${value.id}-label`;
  
            return (
              <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.title} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  
    
  return (
        <div className={classes.root}>
            <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item>{customList('choices', left)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                        >
                        &gt;
                        </Button>
                        <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                        >
                        &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList('chosen', right)}</Grid>
            </Grid>
            
             {removeButton ? null :

        <Grid className={classes.boxActionButton} container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              className={classes.buttonCancel}
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => cancel()}
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              onClick={() => addAssetsHandler(right)}
              >
              Save
            </Button>
          </Grid>
        </Grid>  
      }
      </div> 
  )
}

AssetTransferList.propTypes = {
  classes: PropTypes.object,
  assets: PropTypes.array.isRequired,
  seletedAssets: PropTypes.array,
  addAssetsHandler: PropTypes.func.isRequired,
  removeButton: PropTypes.bool,
  getSeleted: PropTypes.func,
  cancel: PropTypes.func
}

export default compose(
    withStyles(styles)
)(AssetTransferList);


function not(a, b) {
    return a.filter(value => b.indexOf(value) === -1);
  }
  
  function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1);
  }
  
  function union(a, b) {
    return [...a, ...not(b, a)];
}
  


