import React, { useEffect, useContext, useState} from 'react'
import PropTypes from 'prop-types';
import styles from './styles';
import { withFormik, Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';




/* Material */
import { withStyles } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormControl } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';

/* */
import AssetTransferList from './../AssetTransferList';

/* Contexts */
import { LayoutContext } from '../../../../context/LayoutContext';


/* Services */
import * as assetService from '../../../../services/AssetService/asset.service';





const validationSchema = Yup.object().shape({
    title: Yup.string()
});


const PlaylistFormStepper = ({
    classes,
    cancel,
    save
}) => {
    const { t } = useTranslation();
    const { layoutDispatch } = useContext(LayoutContext); 

    const [activeStep, setActiveStep] = useState(0);
    const [assets, setAssets] = useState([]);
    const [seletedAssets, setSeletedAssets] = useState([]);
    const steps = getSteps();

    const handleNext = () => {
        if (activeStep === steps.length -1) {
            const data = {
                title: formik.values.title,
                imageDefaultTime: formik.values.imageDefaultTime,
                assets: seletedAssets
            }
            save(data);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const formik = useFormik({
        initialValues: {
            title: '',
            imageDefaultTime: '60'
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            //
        }
    })

    useEffect(() => {
        const getAllAssets = async () => {
            try {
                const response = await assetService.getAsset();
                setAssets(response.data.data.items);
            } catch (error) {}
        }
        getAllAssets();
    }, [])

   


    const formRender = <form onSubmit={formik.handleSubmit} className={classes.form} >
        <FormControl fullWidth
            margin='normal'>
            <TextField
                id="title"
                fullWidth
                label={t('title')}
                placeholder={t('playlist title')}
                autoComplete="off"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.title && formik.touched.title}
                helperText={(formik.errors.title && formik.touched.title) && formik.errors.title}
            />
        </FormControl>
        <FormControl  margin='normal'>
            <TextField
                id="imageDefaultTime"
                type='number'
                fullWidth
                label={t('image Default Time')}
                autoComplete="off"
                value={formik.values.imageDefaultTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.imageDefaultTime && formik.touched.imageDefaultTime}
                helperText={(formik.errors.imageDefaultTime && formik.touched.imageDefaultTime) && formik.errors.imageDefaultTime}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    min: 1
                }}
            />
        </FormControl>
    </form>

 


    const  getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return formRender
            case 1: {
                const notSeletedAssets = assets.filter(value => seletedAssets.indexOf(value) === -1);
                return <AssetTransferList
                    removeButton={true}
                    assets={notSeletedAssets}
                    seletedAssets={seletedAssets}
                    getSeleted={(data) => {
                        setSeletedAssets(data);
                    }} />
            }
                
            case 2:
                return 'This is the bit I really care about!';
            default:
                return null
        }
    }
  

  return (
      <div className={classes.root}>
          <Typography variant="h4">
              {t('create playlist')}
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel >
              {steps.map((label) => (
                  <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                  </Step>
              ))}
          </Stepper>
          <div>
              {activeStep === steps.length ? (
                  null
              ) : (
                      <div>
                          {getStepContent(activeStep)}
                          <div className={classes.boxButtonAction}>
                              <Button
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                                  className={classes.backButton}
                                >
                                  Back
                            </Button>
                              <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleNext}>
                                  {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                              </Button>
                              <Button
                                  className={classes.buttonCancel}
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => cancel()}

                              >
                                  {t('cancel')}
                              </Button>
                          </div>
                        
                      </div>
                  )}
          </div>
      </div>
  )
}

PlaylistFormStepper.propTypes = {}



export default (
    withStyles(styles)
)(PlaylistFormStepper)


function getSteps() {
    return ['Playlist data', 'Add assets and save'];
}

