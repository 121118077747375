const { getItem, setItem, removeItem } = require('localforage');


export const setToken = async token => {
    const expirationDate = new Date(new Date().getTime() + token.ExpiresIn * 1000);
    await setItem('accessToken', token.AccessToken);
    await setItem('idToken', token.IdToken);
    await setItem('refreshToken', token.RefreshToken);
    await setItem('expirationDate', expirationDate);
}

export const setUserData = async data => {
    await setItem('userData', JSON.stringify(data));
}

export const clearAllDataSession = async () => {
    await removeItem('accessToken');
    await removeItem('idToken');
    await  removeItem('refreshToken');
    await  removeItem('expirationDate');
    await  removeItem('userData');
}

export const getIdToken = async () => await getItem('idToken');
export const getRefreshToken = async () => await getItem('refreshToken');
export const getExpirationDate = async () => await getItem('expirationDate');
export const getUserData = async () => await getItem('userData');