import { createMuiTheme } from '@material-ui/core';
import { COLOR_PRIMARY, COLOR_SECONDARY } from './colors';


const theme =  createMuiTheme({
    overrides: {
      // Style sheet name ⚛️
      palette: {
        primary: '#282828',
        secondary: 'rgb(208,33,42, 1)'
      },
      MuiAppBar: {
         root: {
           padding: '5px 0',
         },
         colorPrimary: {
          backgroundColor: "rgba(40, 40, 40, 1)"
         }
      },
    MuiButton: {
      label: {
          textTransform: 'upercase'
        },
        containedSecondary: {
          backgroundColor: "rgba(40, 40, 40, 1)",
          '&:hover': {
            backgroundColor: "rgba(40, 40, 40, 0.7) !important",
        }
        }, 
        containedPrimary : {
            backgroundColor: "rgb(208,33,42, 1)",
            '&:hover': {
              backgroundColor: "rgb(208,33,42, 0.7)",
            }
      },
      contained: {
        '&.Mui-disabled': {
          backgroundColor: "rgb(208,33,42, 0.4)",
          color: "rgba(255, 255,255,1)"
          }
        }
      },
      MuiLinearProgress: {
        barColorPrimary: {
          backgroundColor: "#4caf50"
        } 
      },
     MuiInput: {
      underline: {
        '&:after': {
          borderBottom: 'rgba(40, 40, 40, 1)'
        }
      }
     },
     MuiFormLabel: {
       root: {
         '&.Mui-focused':{
              color: 'rgba(40, 40, 40, 1)',
         }
       }
     },

     MuiOutlinedInput: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(40, 40, 40, 1)'
          }
        }
     },
     MuiTableHead: {
       root: {
         backgroundColor: 'white'
       }
     },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: 'rgba(40, 40, 40, 0.3)'
      }
    }
  }
});

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5625rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.125rem',
  },
};

export default theme