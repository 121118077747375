import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import styles from './styles';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { PropTypes } from "prop-types";

/* Material-UI */
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {Select} from 'formik-material-ui';
 


const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required')
});


const UserForm = ({
    classes,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    roles,
    user,
    onClose,
    onSave,
    updating,
    onUpdate
}) => {

    const { t } = useTranslation();

    return (
        <>  
            <form onSubmit={handleSubmit} className={classes.form}>
                
                <IconButton className={classes.button} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Grid container spacing={4} direction="column" justify="center">
                    <Typography variant="h5">
                        {t('add user')}
                </Typography>

                    <Grid item>
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    id="name"
                                    label={t('name')}
                                    fullWidth
                                    autoComplete="off"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    helperText={(errors.name && touched.name) && errors.name}
                                    />
                                </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    id="email"
                                    label={t('email')}
                                    fullWidth
                                    autoComplete="off"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.email && touched.email}
                                    helperText={(errors.email && touched.email) && errors.email}
                                    />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink={true} htmlFor="role">
                                    Role
                                </InputLabel>
                                <Field
                                    component={Select}
                                    type="text"
                                    name="roleId"
                                    label={t('role')}
                                    select
                                    variant="standard"
                                    helperText="Role to give the use"
                                    margin="normal"
                                >
                                    {roles.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => onClose()}
                                >
                                    {t('cancel')}
                             </Button>
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="contained" color="primary">
                                    {t('save')}
                               </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
      </>  
    )
};


UserForm.propTypes = {
    classes: PropTypes.object,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    roles: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    updating: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired
}


export default compose(
    withStyles(styles),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: ({ user }) => {
            let values = {};
            if (user) {
                values = {
                    name: user.name,
                    email: user.email,
                    roleId:   user.role
                }
            } else {
                values = {
                    name: '',
                    email: '',
                    roleId: ''
                }
            }
            return values;
        },
        validationSchema: validationSchema,
        handleSubmit: (values, { props: {onSave}}) => {
            onSave(values);
        }
    })

)(UserForm);
