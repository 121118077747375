import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';

/* Material */
import {
    withStyles
} from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';


const DATA_INFO = {
    successForgotPassword: {
        title: "O seu email foi validado!",
        info1: "Irá receber um email para confirmação.",
    },
    errorForgotPassword: {
        title: " Ups! O email introduzido não existe!",
        info1: "Por favor introduza o seu email de registo.",
    },
    successResetPassword: {
        title: "A sua password foi alterada com sucesso."
    },
    errorResetPassword: {
        title: " Ups! Ocorreu um erro.",
        info1: "Por favor tente novamente.",
    },
}

const PopupAuth = ({
    classes,
    type,
    close
}) => {

    const successFogotPasswordRender =
        <div className={classes.boxSuccess}>
            <CheckCircleRoundedIcon className={classes.checkIcon} />
            <p className="title">{DATA_INFO.successForgotPassword.title}</p>
            <p className="info">{DATA_INFO.successForgotPassword.info1}</p>
            <p className={classes.buttonClose} onClick={() => close()}>
                Fechar
            </p>
        </div>
    
    const errorFogotPasswordRender =
        <div className={classes.boxError}>
            <ErrorIcon className={classes.errorIcon} />
            <p className="title">{DATA_INFO.errorForgotPassword.title}</p>
            <p className="info">{DATA_INFO.errorForgotPassword.info1}</p>
            <p className={classes.buttonClose} onClick={() => close()}>
                Fechar
            </p>
        </div>
    

    const successResetPasswordRender =
        <div className={classes.boxSuccess}>
            <CheckCircleRoundedIcon className={classes.checkIcon} />
            <p className="title">{DATA_INFO.successResetPassword.title}</p>
            <p className={classes.buttonClose} onClick={() => close()}>
                Fechar
            </p>
        </div>
    
    const errorResetPasswordRender =
        <div className={classes.boxError}>
            <ErrorIcon className={classes.errorIcon} />
            <p className="title">{DATA_INFO.errorResetPassword.title}</p>
            <p className="info">{DATA_INFO.errorResetPassword.info1}</p>
            <p className={classes.buttonClose} onClick={() => close()}>
                Fechar
            </p>
        </div>
    


    let render = null;
    
    switch (type) {
        case 'SUCCCESS_FORGOT_PASSWORD':
            render = successFogotPasswordRender
            break;
        case 'ERROR_FORGOT_PASSWORD':
            render = errorFogotPasswordRender
            break;
        case 'SUCCCESS_RESET_PASSWORD':
            render = successResetPasswordRender
            break;
        case 'ERROR_RESET_PASSWORD':
            render = errorResetPasswordRender
            break;
        default:
            break;
    }
  return (
      <div className={classes.root}>
          {render}
     </div>
  )
}

PopupAuth.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string,
    close: PropTypes.func,
    login: PropTypes.func
}

export default compose(
    withStyles(styles)
)(PopupAuth)


