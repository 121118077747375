export default theme => ({
    switchBase: {
        color: '#FFF',
        '&$checked': {
            color: '#52d869',
        },
        '&$checked + $track': {
            backgroundColor: '#52d869',
        },
    },
    checked: {},
    track: {},
});