import React from 'react';
import { compose } from 'recompose';
import styles from './styles';


/* Material-UI */
import { withStyles } from '@material-ui/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Fade from '@material-ui/core/Fade';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';



const ProfileMenu = ({
  classes,
  anchorRef,
  open,
  switchAccount,
  handleClose
}) => {



    return (
        <Popper open={open} className={classes.root} elevation={8} placement={"bottom-start"}
                anchorEl={anchorRef.current} transition >
              {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
               <Paper>
               <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" >
                    <MenuItem onClick={switchAccount}>
                      <ListItemIcon>
                      <SendIcon fontSize="small" />
                        </ListItemIcon>
                      <Typography variant="inherit">Switch tenant</Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
            </Paper>
          </Fade>
        )}
        </Popper>
    )
}

export default compose(
  withStyles(styles)
)(ProfileMenu);