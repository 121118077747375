import React, { useState } from  'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import styles from './styles';



/* Material-UI */
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TvIcon from '@material-ui/icons/Tv';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import RestaurantMenuIcon from '@material-ui/icons/MenuBook';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import SettingsIcon from '@material-ui/icons/Settings';

/* Components  */
import ListItemLink from '../ListItemLink';



const ListItemMenu = ({
    classes,
    product,
    onClickLink
}) => {

    const { t } = useTranslation();

    const {modules} = product;

    const [open, setOpen] = useState(false);

    const productMenu = getProductMenu(product);


    const handleClick = () => {
        setOpen(!open);
    };
    


    const collapseHandler = modules.length ?
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {modules.map( module => {
                    const moduleMenu = getModuleMenu(module.id, t);
                    return moduleMenu ?
                        <ListItemLink key={module.id}
                             productId={product.id}
                            productName={product.name}
                            onClickLink={onClickLink}
                             {...moduleMenu}
                        />
                        : null
                })}
        </List>
      </Collapse>
    : null;

    return (
         <>     
          {productMenu ?   
          <ListItem button onClick={handleClick}
                    className={classes.item}>
            <ListItemIcon>
                {productMenu.icon}
            </ListItemIcon>
            <ListItemText primary={t(productMenu.title)} />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        : null}
        {collapseHandler}
       </> 
    );
}

export default compose(
    withStyles(styles)
  )(ListItemMenu);


const getProductMenu = (product) => {
    switch (product.type) {
        case  'ADMIN':
           return {
                title: product.name,
                link:   null,
                icon:  <SupervisorAccountIcon />
              }
        case 'CORPORATE_TV':      
            return {
                title:  product.name,
                link:   null,
                icon:  <TvIcon />
            }
        case 'COMMERCE':
            return {
                title: product.name,
                link: null,
                icon: <RestaurantIcon />
            }
        default:
            return null;
    }
}

const getModuleMenu = (maduleId, translate) => {
    switch (maduleId) {
        case  'USER':
           return {
                title: translate('sidebar admin utilizador'),
                link:   '/users/user-management',
                icon:  <PersonIcon />
              }
        case 'ROLE':      
            return {
                title: translate('sidebar admin funcao'),
                link:   '/roles/role-management',
                icon:  <AssignmentIndIcon />
             }
        case 'ASSET_MANAGER':      
            return {
                title: 'asset',
                link:   '/assets/asset-management',
                icon:  <WebAssetIcon />
            }
        case 'CTV_PLAYLIST': {
            return {
                title: 'playlist',
                link:   '/playlist',
                icon:  <PlaylistPlayIcon />
            }
        } 
        case 'MENU': {
            return {
                title: translate('sidebar menu ementa'),
                link: '/ementa',
                icon: <RestaurantMenuIcon />
            }
        }     
        case 'CONFIGURATIONS': {
            return {
                title: translate('sidebar menu configurations'),
                link: '/ementa/configurations',
                icon: <SettingsIcon />
            }
        }     
        default:
            return null;
    }
}
