import axios from '../../axios';

import { getIdToken, clearAllDataSession, getExpirationDate, getUserData } from '../storage';


const AUTH = process.env.REACT_APP_AUTH
const ADMIN = process.env.REACT_APP_ADMIN
const LOGIN = process.env.REACT_APP_LOGIN
const CHALLLENGE = process.env.REACT_APP_CHALLENGE
const USERS_TENANTS = process.env.REACT_APP_USER_TENANTS
const REACT_APP_ROLES_LIST = process.env.REACT_APP_ROLES_LIST;
const RESET_PASSWORD = process.env.REACT_APP_RESET_PASSWORD
const RESET_PASSWORD_CONFIRM = process.env.REACT_APP_RESET_PASSWORD_CONFIRM







/* Login */
 export const login = async (email, password, tenant) => {
    const postData = {
        username: email,
        password: password
    };
    if(tenant) {
        postData['tenantId'] = tenant;
    }
     return await axios.post(`${AUTH}/${LOGIN}`, postData);
}


export const authChallenge = async (postData) => await axios.post(`${AUTH}/${CHALLLENGE}`, postData);
  

export const getRoles = async () => await axios.get(`${ADMIN}/${REACT_APP_ROLES_LIST}`);


export const resetPassword = async (postData) => await axios.post(`${AUTH}/${RESET_PASSWORD}`, postData);

export const resetPasswordConfirm = async (postData) => await axios.post(`${AUTH}/${RESET_PASSWORD_CONFIRM}`, postData);



/* Get list of tenants by user */
export const getUserTenants = async () => {
    try {
        const response = await axios.get(`${AUTH}/${USERS_TENANTS}`);
         return response.data.tenant;
     } 
     catch (error) {
         return Promise.reject();
     }
}




 /* Check if user is authenticated */
export  const checkAuth = async () => {
     const token = await getIdToken();
     if (!token) {
         clearAllDataSession();
         return null;
     } else {
        const expirationDate = new Date( await getExpirationDate());
        if (expirationDate <= new Date()) {
         clearAllDataSession();
        } else {
            let userData = await getUserData();
            if(!userData) {
               clearAllDataSession();      
            } else {
                userData = JSON.parse(userData);
                return userData;
            }
        }
     }
 }