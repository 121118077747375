import React, {useEffect, useReducer, useContext} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from "prop-types";
import { compose } from 'recompose';
import moment from 'moment';
import styles from './styles';

/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

/* Components */
import TableData from './../../components/TableData';
import PlaylistFormStepper from './components/PlaylistFormStepper';


/* Contexts */
import {LayoutContext} from '../../context/LayoutContext';

/* Services */
import * as playlistService from '../../services/playlistService/playlist.service';
import {useAlertDialog} from '../../services/AlertDialogService/alertDialog.service.js';







const initialState = {
    playlists: [],
    dataTable: [],
    openForm: false
}

/* REDUCER */
const reducer = (state, action) => {
    switch (action.type) {
        case "SET_PLAYLISTS":
            return {
                ...state,
                playlists: action.payload,
                dataTable: setDataTable(action.payload)
            };
        case "SET_DATATABLE":
            return {
                ...state,
                dataTable: setDataTable(action.payload)
            }  
        case "SET_FORM":
            return {
                ...state,
                formOpen: !state.formOpen
            }
        default:
            return state;
    }
}


const Playlist = ({
    classes
}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const {layoutDispatch} = useContext(LayoutContext); 
    const alertDialog = useAlertDialog();
    const [state, dispatch] = useReducer(reducer, initialState);

    let columns = [
        { title: t('title'), field: 'title' },
        { title: t('image default time'), field: 'image_default_time' },
        { title: t('create by'), field: 'create_by' },
        { title: t('update on'), field: 'update_on', type: 'date' },
        { title: t('create on'), field: 'create_on', type: 'datetime' },
        { title: t('update by'), field: 'update_by', type: 'datetime' },
    ]


    const actions=[
        {
          icon: 'settings',
          tooltip: t('manage'),
          onClick: (event, rowData) => {
            history.push(`/playlist/${rowData.playlist_id}/management`);
          }
        },
        {
            icon: 'delete',
            tooltip: t('delete'),
            onClick: (event, rowData) => {deletePlaylistHandler(rowData)}
        }
    ];

    useEffect(() => {
        const getAllPlaylist = async () => {
            layoutDispatch({ type: 'LOADING', data: {loading: true}});
            try {
                const response = await playlistService.getPlaylists();
                layoutDispatch({
                    type: 'LOADING',
                    data: {loading: false}
                });
                dispatch({
                    type: "SET_PLAYLISTS",
                    payload: response.data.data
                })
            } catch (error) {
                layoutDispatch({ type: 'LOADING', data: {loading: false}});
            }
        };
        getAllPlaylist();
    }, []);



    const deletePlaylistHandler =  (rowData) => {
        const dataAlert = {
            title: t('remove playlist'),
            info: t('are you sure of remove this playlist')
            }
            alertDialog({
            ...dataAlert
            })
            .then( async () => {
                await playlistService.deletePlaylist(rowData.playlist_id);
                const playlistUpdated = state.playlists.filter(playlist => playlist.playlist_id !== rowData.playlist_id);
                dispatch({
                    type: "SET_PLAYLISTS",
                    payload: playlistUpdated
                })
            })
            .catch(() => {})
    }

    /* Handler add playliest click */
    const addHandler = () => {
        dispatch({ type: "SET_FORM" })
    }


    /* Handle create playlist  */
    const createPlaylist = async (data) => {
        dispatch({ type: "SET_FORM" })
        const contentToAdd = data.assets.map(asset => {
            return {
                enable: true,
                assets: {
                    id: asset.id,
                    type: asset.type,
                    createdby: asset.created_by,
                    bucket_key: asset.bucket_key,
                    title: asset.title
                }
            }
        })
        const playlist = {
            imageDefaultTime: data.imageDefaultTime,
            content: contentToAdd,
            title: data.title
        }
        try {
            layoutDispatch({ type: 'LOADING', data: { loading: true } });
             await playlistService.createPlaylist(playlist);
            getAllPlaylist();
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        } catch (error) {
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        }
    }

    const getAllPlaylist = async () => {
        layoutDispatch({ type: 'LOADING', data: { loading: true } });
        try {
            const response = await playlistService.getPlaylists();
            layoutDispatch({
                type: 'LOADING',
                data: { loading: false }
            });
            dispatch({
                type: "SET_PLAYLISTS",
                payload: response.data.data
            })
        } catch (error) {
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        }
    };




    let playlistsTableRender = state.dataTable ? 
        <Box className={classes.boxTable}>
            <TableData
                title={t('list of playlist')}
                columns={columns}
                data={state.dataTable}
                actions={actions} />
        </Box>
        : null; 
    

    const formRender =
        <Dialog
            open={state.formOpen}
            disableBackdropClick
            onClose={() => dispatch({ type: "SET_FORM" })}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <PlaylistFormStepper
                    cancel={() => dispatch({ type: "SET_FORM" })}
                    save={(data) => createPlaylist(data)} />
            </DialogContent>
        </Dialog>

  

    return (
        <Container maxWidth={false}>
            <Typography variant="h3">
                {t('playlist management')}
            </Typography>
            <div className={classes.pageHeader}>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddCircleIcon />}
                    onClick={addHandler}>
                    {t('add playlist')}
                </Button>
            </div> 

            {playlistsTableRender}
            {formRender}
        </Container>  

    );
}

Playlist.propTypes = {
    classes: PropTypes.object
  }

  
export default compose(
    withStyles(styles)
  )(Playlist);


const setDataTable = (playlists) => {
    return playlists.map(playlist => { 
        return  {  
            playlist_id: playlist.playlist_id,
            title: playlist.title,
            create_by: playlist.create_by,
            product_id: playlist.product_id,
            image_default_time: playlist.image_default_time,
            update_on: `${moment(playlist.update_on).format('ll')} | ${moment(playlist.update_on).format('LT')}`,
            create_on:  `${moment(playlist.create_on).format('ll')} | ${moment(playlist.create_on).format('LT')}`,
            update_by: playlist.update_by
        }
    })
}