import React, { useEffect, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import styles from './styles';

import moment from 'moment';

/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';


/* Components */
import AssetForm from './components/Forms/AssetForm';
import FilterForm from './components/Forms/FilterForm';
import TableData from './../../components/TableData';

/* Utilities */
import {  getFileMimeType } from '../../shared/utilities/utility';

/* Services */
import * as assetService from '../../services/AssetService/asset.service';
import {useAlertDialog} from '../../services/AlertDialogService/alertDialog.service.js';

/* Contexts */
import {LayoutContext} from '../../context/LayoutContext';

/* Constants */
import * as actionsType from "../../actions/actionsType";



const initialState = {
    assets: [],
    dataTable: [],
    formOpen: false,
    showFilter: false,
    dataFilter: {
        limit: 20,
        state: null,
        type: null
    },
    updatingAsset: false,
    asset: null
}

/* REDUCER */
const reducer = (state, action) => {
    switch (action.type) {
        case "SET_ASSETS":
            return {
                ...state,
                assets: action.payload,
                dataTable: setDataTable(action.payload)
            }
        case "SET_FORM":
            return {
                ...state,
                formOpen: !state.formOpen
            }
        case "SET_UPDATING_ASSET":
            return {
                ...state,
                updatingAsset: action.payload
            }
        case "SET_ASSET":
            return {
                ...state,
                asset: action.payload
            }
        case "SET_SHOW_FILTER":
            return {
                ...state,
                showFilter: !state.showFilter
            }
        case "SET_DATA_FILTER":
            return {
                ...state,
                dataFilter: action.payload
            }
        default:
            return state;
    }
}


const Asset = ({
    classes
}) => {

    const { t } = useTranslation();
    const {layoutDispatch} = useContext(LayoutContext); 
    const alerDialog = useAlertDialog();
    const [state, dispatch] = useReducer(reducer, initialState);


    
    const columns = [
        { title: t('name'), field: 'name' },
        { title: t('type'), field: 'type' },
        { title: t('date created'), field: 'dateCreated' },
        { title: t('state'), field: 'state' }
    ]


    const actions = [
        {
            icon: 'delete',
            tooltip: 'Delete',
            onClick: (event, rowData) => { deleteAssetHandler(rowData) }
        },
        {
            icon: 'edit',
            tooltip: 'Edit',
            onClick: (event, rowData) => { editAssetHandler(rowData) }
        },
        {
            icon: 'filter_list',
            tooltip: 'Filter',
            isFreeAction: true,
            onClick: () => {
                dispatch({ type: 'SET_SHOW_FILTER' });
            }
        }
    ];



    useEffect(() => {
        const getAllAssets = async () => {
            layoutDispatch({ type: 'LOADING', data: { loading: true } });
            try {
                const response = await assetService.getAsset(state.dataFilter);
                layoutDispatch({
                    type: 'LOADING',
                    data: { loading: false }
                });
                dispatch({
                    type: "SET_ASSETS",
                    payload: response.data.data.items
                })
            } catch (error) {
                layoutDispatch({ type: 'LOADING', data: { loading: false } });
            }
        };
        getAllAssets();
    }, [layoutDispatch, state.dataFilter]);


    
    /* Get all assets */ 
    const getAllAssets = async () => {
        layoutDispatch({ type: 'LOADING', data: { loading: true } });
        try {
            const response = await assetService.getAsset(state.dataFilter);
            layoutDispatch({
                type: 'LOADING',
                data: { loading: false }
            });
            dispatch({
                type: "SET_ASSETS",
                payload: response.data.data.items
            })
        } catch (error) {
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        }
    };
    
  
    /* Handler add asset click */
    const addHandler = () => {
        dispatch({ type: "SET_ASSET", payload: null });
        dispatch({ type: "SET_FORM" })
    }

    /* Create a asset [Upload file and add asset data] */
    const createAssetHandler = async (data) => {
        try {
            layoutDispatch({ type: 'LOADING', data: { loading: true } });
            dispatch({ type: "SET_FORM" })
            const fileInfo = getFileMimeType(data.file);
            let response = await assetService.getUploadFileUrl(fileInfo);
            const dataUpload = response.data.data;
            assetService.uploadFile(dataUpload.url, data.file, async (progress) => {
                const percentage = Math.round((progress.loaded / progress.total) * 100);
                if (percentage === 100) {
                    const postData = {
                        title: data.name
                    }
                    response = await assetService.updateAsset(dataUpload.sk, postData)
                    layoutDispatch({ type: 'LOADING', data: { loading: false } });
                    getAllAssets();
                    showSnackbar('success', t('Asset successfyly added'));
                }
            });
        } catch (error) {
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
            showSnackbar('error', t('error on added asset'));
        }   
    }

   
     /* Detail handler */
    const editAssetHandler = (asset) => {
        dispatch({ type: "SET_ASSET", payload: asset })
        dispatch({ type: "SET_UPDATING_ASSET", payload: true })
        dispatch({ type: "SET_FORM" })
    }

   
    /* Delete handler */
    const deleteAssetHandler = async (asset) => {
        const dataAlert = {
        title: t('delete asset'),
        info: t('are you sure of delele this asset')
        }
        alerDialog({
        ...dataAlert
        })
        .then(async () => {
            try {
                layoutDispatch({ type: 'LOADING', data: { loading: true } });
                await assetService.deleteAsset(asset.sk);
                const assetsUpdate = state.assets.filter(asst => asst.id !== asset.id);
                dispatch({
                    type: "SET_ASSETS",
                    payload: assetsUpdate
                })
                showSnackbar('success', t('Asset successfyly deleted'));    
                layoutDispatch({ type: 'LOADING', data: { loading: false } });
            } catch (error) {
                showSnackbar('error', t('Error on delete asset'));
                layoutDispatch({ type: 'LOADING', data: { loading: false } });
            }
        })
        .catch(() => {})
    }


    /* Call service to update asset */
    const updateAssetHandler = async (data) => {
        try {
            const postData = {
                title: data.name
            }
            dispatch({ type: "SET_FORM" });
            dispatch({ type: "SET_UPDATING_ASSET", payload: false })
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
            await assetService.updateAsset(state.asset.sk, postData)
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
            showSnackbar('success', t('Asset successfyly added'));
            getAllAssets();
        } catch (error) {
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
            showSnackbar('error', t('Error on added asset'));
        }
    }

 
    const showSnackbar = (severiry, message) => {
        const snakbar = {
            show: true,
            message: message,
            severiry: severiry
          }
          layoutDispatch({ type: actionsType.OPEN_SNACKBAR, data: {snakbar: snakbar}});
    }


    /* Handler apply filter */
    const applyFilter = (dataFilter) => {
        dispatch({ type: "SET_SHOW_FILTER" })
        dispatch({
            type: "SET_DATA_FILTER",
            payload: dataFilter
        })
    }
    
   /* Handler clear filter */
    const clearFilter = () => { 
        dispatch({ type: "SET_SHOW_FILTER" })
        const dataFilter = {
            limit: 20,
            state: null,
            type: null
        };
        dispatch({
            type: "SET_DATA_FILTER",
            payload: dataFilter
        })
   }

    const drawerFilterRender = 
        <Drawer anchor="right" open={state.showFilter}>
            <FilterForm
                dataFilter={state.dataFilter}
                close={() => dispatch({ type: "SET_SHOW_FILTER" })}
                applyFilter={(data) => applyFilter(data)}
                clearFilter={() => clearFilter()}
            />        
        </Drawer>

    

    const assetsTableRender = state.dataTable ?
        <Box className={classes.boxTable}>
            <TableData
                title={t('list of assets')}
                columns={columns}
                data={state.dataTable}
                actions={actions} />
        </Box>
        : null; 
    
    const formRender =
        <Dialog
            open={state.formOpen}
            disableBackdropClick
            onClose={() => dispatch({ type: "SET_FORM" })}
            maxWidth="md"
            fullWidth
        >
            <DialogContent>
                <AssetForm
                    asset={state.asset}
                    updating={state.updatingAsset}
                    onUpdate={(data) => updateAssetHandler(data)}
                    onSave={(data) => createAssetHandler(data)}
                    onClose={() => dispatch({ type: "SET_FORM" })}
                />
            </DialogContent>
        </Dialog>

    


    return ( 
        <>
            <Container maxWidth={false}>
                <Typography variant="h3">
                    {t('asset management')}
                </Typography>    
                <div className={classes.pageHeader}>
                    <Button
                        variant="contained" 
                        color="secondary" 
                        startIcon={<AddCircleIcon />}
                        onClick={addHandler}>
                        {t('add asset')}
                    </Button>
                </div> 
                {assetsTableRender}
                {formRender}
                {drawerFilterRender}
            </Container>  
        </>     
    );
}




export default compose(
    withStyles(styles)
  )(Asset);

const setDataTable = (assets) => {
    return assets.map(dt => {
        return {
            id: dt.id,
            sk: dt.sk,
            name: dt.title,
            type: dt.type,
            dateCreated: `${moment(dt.created_on).format('ll')} | ${moment(dt.created_on).format('LT')}`,
            state: dt.state
        }
    })
}


  /*
const dataTableRender = useMemo(() => {

    /* Set row per page and call get assets 
    const setRowPerPageHandle = (rowPerPage) => {
        setDataTable([]);
        setPages([]);
        setCurrentPage(1);
        const params = { limit: rowPerPage };
        setRowPerPage(rowPerPage);
        getAssets(params);
    }

    /* Get next page assets 
    const getNextPage = () => {
        setCurrentPage(previous => previous + 1);
        const params = { limit: rowPerPage, lastEvaluatedKey: nextKey }
        getAssets(params);
    }

    /* Get previous page assets 
    const getPreviousPage = () => {
        const params = { limit: rowPerPage, lastEvaluatedKey: pages[currentPage - 2] }
        getAssets(params);
        setCurrentPage(previous => previous - 1);
    }


    return (
        <DataTable
            columns={columns}
            data={dataTable}
            pagination={pagination}
            rowsPerPage={rowPerPage}
            setRowPerPage={(n) => setRowPerPageHandle(n)}
            currentPage={currentPage}
            nextPage={getNextPage}
            previousPage={getPreviousPage}
            showFilter={() => setShowFilter(true)}
        />
    );
}, [getAssets, setShowFilter, dataTable, pagination, currentPage, rowPerPage, nextKey, pages]);

*/