import React, {createContext, useReducer} from "react";
import {menuReducer} from "../reducers/menuReducer";


export const MenuContext = createContext();

const initialState = {
    opened: true
}

const MenuContextProvider = (props) => {

    const [menuState, menuDispatch]  = useReducer(menuReducer, initialState);
    const value = {menuState, menuDispatch};

    return  (
        <MenuContext.Provider value={value}>
            {props.children}
        </MenuContext.Provider>
    )
}

export default MenuContextProvider;

