export default theme => ({
    paper: {
        margin: '30px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '600px'
    },
    boxActionButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '20px 20px'
    },
    buttomCancel: {
        marginRight: '10px'
    }
})