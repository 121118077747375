
/* Update an object */
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

/* Check  if a value is valid according to the rules */
export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
}


/* Return tye and subtype from file */
export const getFileMimeType = (file) => {
    const mimeType = file.type;
    const typeExtension = mimeType.split('/');
    return {type: typeExtension[0], extension: typeExtension[1]}
}


/* Remove  blank attributes from a aobject */
export const removeEmptyProperty = (object) => {
    for (var prop in object) { 
        if (!object[prop]) { 
            delete object[prop]; 
        } 
    }
    return object;
}