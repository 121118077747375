export default theme => ({

    root: {

    },
    form: {
        padding: 30
    },
    button: {
        position: "absolute",
        right: 10,
        top: 10
    },
    title: {
        marginBottom: 20
    },
    input: {
        flex: 1
    },
    selectInput: {
        width: "100%"
    },
    formControl: {
        margin: '20px auto',
        width: "100%"
    },
    inputText: {
        margin: '20px auto'
    },
    uploadArea: {
       display: 'flex',
       flexDirection: 'column'
    },
    buttonUpload: {
       backgroundColor: '#0063cc',
       color: 'white',
       paddingTop: '10px'
    },
    inputFile: {
      margin: '20px auto',
      display: 'none'
    },
    progress: {
        height: '10px',
        background: 'white',
        color: 'red'
    }
  });



