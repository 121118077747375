export default theme => ({
    root: {
        width: '100%',
        padding: '30px'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    form: {
        padding: '40px'
    },
    boxButtonAction: {
        display: 'flex'
    },
    buttonCancel: {
        marginLeft: 'auto'
    }
});