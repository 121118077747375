export default theme => ({
    root: {
      width: '420px',
      padding: '8px 16px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    header: {
        marginBottom: '20px'
    },
    body: {
      flexGrow: 1,
      '& .MuiFormControl-root': {
        margin: 'auto',
        padding: '20px'
      },
    },
    footer: {
      padding: '20px 0',
      '& > button': {
          margin: '10px auto'
      }
    }
  });