export default theme => ({
    root: {
        margin: '30px 0'
    },
    pageHeader: {
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'flex-end'
    }
  });
