
export default theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 'fit-content',
      padding: '10',
      textAlign: 'center'
    },
    avatar: {
      width: 60,
      height: 60
    },
    menuButton: {
      position: 'absolute',
      top: '10px',
      right: '10px'
    }
});
  
