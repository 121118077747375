export default theme => ({
    root: {
        maxHeight: '800px',
        display: 'flex',
        flexDirection: 'column'
    },
    imageBox: {
        overflow: 'auto',
        padding: '0 20px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    boxButton: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        '& svg': {
            margin: '0 10px',
            cursor: 'pointer'
        },
        '& .closeIcon': {
            cursor: 'pointer',
            marginleft: 'auto',
            position: 'absolute',
            right: '16px',
            color: '#A0A0A0'
        }
    },
    footerBox: {
        display: 'flex',
        justifyContent: 'center',
        margin: '30px 0 20px',
        '& p:first-child': {
            color: '#8C161E',
            fontSize: '160x',
            lineHeigh: '29px',
            cursor: 'pointer'
        }
    }
})