import  React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './styles';

/* Material UI */
import {
  withStyles,
  Drawer,
  Divider
} from '@material-ui/core';

/* Components */
import SidebarNav from './components/SidebarNav';
import Profile from './components/Profile';


const Sidebar = ({
  classes,
  variant,
  open,
  onClose,
  onClickLink,
  user
}) => {




    return (
          <Drawer
            classes={{ paper: classes.drawer }}
            variant={variant}
            anchor="left"
            open={open}
            onClose={onClose}>
            <div className={classes.root}>
              <Profile user={user} />
              <Divider className={classes.divider} />
              <SidebarNav user={user} onClickLink={onClickLink}/>   
            </div> 
        </Drawer>
    );
}

Sidebar.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object
};

export default compose(
  withStyles(styles)
)(Sidebar);