export default theme => ({
    root: {

    },
    form: {
        padding: 30
    },
    button: {
        position: "absolute",
        right: 10,
        top: 10
    },
    title: {
        marginBottom: 20
    },
    input: {
        flex: 1
    },
    selectInput: {
        width: "100%"
    },
    formControl: {
        margin: '20px auto',
        width: "100%"
    }
})
