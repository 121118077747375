import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { compose } from 'recompose';
import styles from './styles';

/* Material-UI */
import { withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

/* Constants */
import   icons from '../../../../../constants/icons';



const languages = [
    {id: 'en', label: 'English', icon: icons.flagEnglish},
    {id: 'pt',  label: 'Português', icon: icons.flagPortugues},
]


const MenuLanguage  = ({
  classes
})  => {

    const [flag, setFlg] = useState (languages[0]);

    const { t, i18n } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const currentLanguage = languages.find(language => language.id === i18n.language);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }

     setOpen(false);
  };

  const handleClickLanguage = (language) => {
      const lng = language.id;
      i18next.changeLanguage(lng);
      setOpen(false);
  }

    return (
        <div className={classes.root}>
          
            <Button
                className={classes.buttonToogleMenu}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                startIcon={<img alt="flag" src={currentLanguage.icon}/>}
                endIcon={<ArrowDropDownIcon />}
                onClick={handleToggle}
            >
          {currentLanguage.label}
        </Button>
       
            <Popper
               className={classes.menu}
               placement="bottom-end"
               open={open}
               anchorEl={anchorRef.current}
               role={undefined}
               transition
               disablePortal>
             {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow">
                    {languages.map((language)=> (
                    <MenuItem
                        key={language.id}
                        onClick={() => handleClickLanguage(language)}>
                        <ListItemIcon>
                          {<img alt="flag" src={language.icon}/>}
                        </ListItemIcon>
                        {language.label}
                    </MenuItem>
                ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
            )}
           </Popper>
        </div>
    )
}

export default compose(
  withStyles(styles)
)(MenuLanguage);