import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {Context} from './../context';


 const PrivateRoute = ({component: Component, ...rest}) => {

     const { state } = useContext(Context);
     
    return (
    <Route
     {...rest} 
     render = {props => (
        state.user.isAuthenticated ? (
            <Component {...props}/>
        ) :  (
            <Redirect to={{pathname: '/auth/login'}}/>
        ))}
    />
    )
}

export default PrivateRoute;

PrivateRoute.propTypes = {
    component: PropTypes.element
  };
