import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import ContextProvider from './context';

import './i18n';



const app = (
    <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
            <ContextProvider>
                    <App />
            </ContextProvider>
        </BrowserRouter>
    </Suspense>
   
   
);


ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
