import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import styles from './styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';


/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';




const PlaylistInfoTable = ({
    classes,
    playlist
}) => {

    const { t } = useTranslation();
    const [dataTable, setDataTable] = useState([]);


    useEffect(() => {
        createData(playlist);
    }, [playlist])


    const createData = (playlist) => {
       
       
        if (Object.keys(playlist).length) {
            const totalOfAssets = playlist.content.length;
            const totalOfAssetsEnable = playlist.content.filter(cont => cont.enable).length;
            const rows = [
                {title: t("ID"), value: playlist.playlist_id },
                {title: t('create on'), value: `${moment(playlist.create_on).format('ll')} | ${moment(playlist.create_on).format('LT')}` },
                {title: t('create by'), value: playlist.create_by },
                {title: t('update on'), value: `${moment(playlist.update_on).format('ll')} | ${moment(playlist.update_on).format('LT')}` },
                {title: t('update by'), value: playlist.update_by },
                {title: t('total of assets'), value: totalOfAssets },
                {title: t('total enabled assets'), value: totalOfAssetsEnable }

            ]
            setDataTable(rows);
        }
      }

      

    return (
        <Table className={classes.table} aria-label="simple table">
            <TableBody>
            {dataTable.map((row) => (
                <TableRow key={row.title}>
                    <TableCell component="th" scope="row">
                        {row.title}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                </TableRow>
            ))}
            </TableBody>
      </Table>
    );
}

export default compose(
    withStyles(styles)
)(PlaylistInfoTable);

