import axios from '../../axios';


const ADMIN = process.env.REACT_APP_ADMIN;
const ROLE_LIST = process.env.REACT_APP_ROLES_LIST;



/* Get all users from API */
export const getRoles = async () => await axios.get(`${ADMIN}/${ROLE_LIST}`);

