import React, {useState, useEffect} from 'react';
import { compose } from 'recompose';
import styles from './styles';
import * as Yup from 'yup';
import { withFormik, Field } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import { PropTypes } from "prop-types";


/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';







const type = [
    { value: 'all', label: 'All' },
    { value: 'video', label: 'Video' },
    { value: 'image', label: 'Image' },
    { value: 'pdf', label: 'Pdf' }
]

const stateAsset = [
    { value: 'all', label: 'All' },
    { value: 'draft', label: 'Draft' },
    { value: 'published', label: 'Published' },
    { value: 'enable', label: 'Enable' },
    { value: 'disable', label: 'Disable' }
]

const FilterForm = ({
    classes,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    close,
    applyFilter,
    clearFilter,
    dataFilter
}) => {




    /* Handler click clear filter */
    const clearFilterHandle = () => {
        clearFilter();
    }



    return (
        <form onSubmit={handleSubmit}
           className={classes.root}
        >
            <div className={classes.header}>
                <IconButton
                   aria-label="close"
                   onClick={() => close()}>
                   <CloseIcon />
                </IconButton>
                CLOSE
            </div>

            <div className={classes.body}>
                <FormControl
                    margin='normal'
                    variant='outlined'
                    fullWidth>
                    <FormLabel>Limit</FormLabel>
                    <TextField
                        id="limit"
                        type='number'
                        fullWidth
                        min={1} 
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: 1
                        }}
                        value={values.limit}
                        onChange={handleChange}
                        onBlur={handleBlur('limit')}
                    />
                </FormControl>
                <FormControl
                    margin='normal'
                    fullWidth>
                    <FormLabel component="legend">Type</FormLabel>
                    <Field
                        component={Select}
                        type="text"
                        name="type"
                        select= "true"
                        fullWidth
                        value={values.type}
                        onChange={handleChange('type')}
                        onBlur={handleBlur('type')}
                    >
                        {type.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Field>
                </FormControl>

                <FormControl
                    margin='normal'
                    fullWidth>
                    <FormLabel component="legend">State</FormLabel>
                    <Field
                        component={RadioGroup}
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur('state')}
                    >
                        {stateAsset.map(state => (
                            <FormControlLabel
                                key={state.value}
                                value={state.value}
                                control={<Radio />}
                                label={state.label}
                            />
                        ))}
                    </Field>
                </FormControl>
            </div>

            <div className={classes.footer}>
                <Button
                    fullWidth
                    variant="contained"
                    color="default"
                    startIcon={<DeleteForeverIcon />}
                    onClick={() => clearFilterHandle()}
                >
                    CLEAR
               </Button>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    >
                    APPLY
                </Button>
            </div>
        </form>
    );
}

export default compose(
    withStyles(styles),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: ({ dataFilter = {} }) => {
            return {
                limit: dataFilter.limit,
                state: dataFilter.state ? dataFilter.state : 'all',
                type: dataFilter.type ? dataFilter.type :  'all'           }
        },
        handleSubmit: (values, { props: { applyFilter } }) => {
            const data = {
                ...values,
                state: values.state === 'all' ? null : values.state,
                type: values.type === 'all' ? null : values.type
            }
            applyFilter(data);
        }
    })
  )(FilterForm);