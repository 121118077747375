import { createMuiTheme } from '@material-ui/core';
import {
    COLOR_INPUT_LABEL,
    COLOR_INPUT_LINE,
    COLOR_PRIMARY,
    COLOR_PRIMNARY_HOVER,
    BUTTOM_DISABLED,
    BUTTOM_DISABLED_TEXT

} from './colors';


const theme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                color: COLOR_INPUT_LABEL,
                '&.Mui-focused': {
                    color: COLOR_INPUT_LABEL,
                },
                '&.Mui-error': {
                    color: COLOR_INPUT_LABEL,
                }
            }
        },
        MuiInputBase: {
            input: {
                color: 'white'
            }
        },
        MuiInput: {
            underline: {
                '&:hover:not($disabled):before': {
                    borderBottomColor: COLOR_INPUT_LINE,
                },
                '&:before': {
                    borderBottomColor: COLOR_INPUT_LINE
                },
                '&:after': {
                    borderBottomColor: COLOR_INPUT_LINE
                },
                '&.Mui-error': {
                    '&:after': {
                        borderBottomColor: COLOR_PRIMARY
                    }
                }
            }
        },
        MuiFormHelperText: {
            root: {
                '&.Mui-error': {
                    color: `${COLOR_PRIMARY} !important`
                }
            }
        },
        MuiButton: {
            root: {
                fontFamily: 'Open Sans',
                backgroundColor: COLOR_PRIMARY,
                padding: '8px',
                color: '#FFFFFF',
                '&:hover': {
                    backgroundColor: '#E62D2B !important'
                },
                '&.Mui-disabled': {
                    backgroundColor: BUTTOM_DISABLED,
                    color: BUTTOM_DISABLED_TEXT,
                }
            },
            text: {
                paddingLeft: '20px',
                paddingRight: '20px'
            }
        },
        MuiCheckbox: {
            root: {
                color: `${COLOR_PRIMARY} !important`,
                padding: 0
            }
        },
        MuiTypography: {
            h1: {
                fontFamily: 'Open Sans'
            }
        } 
    }
});



export default theme