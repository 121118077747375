import React from 'react';
import {
    Page,
    Text,
    View,
    Image,
    Document,
    StyleSheet,
    Font
} from '@react-pdf/renderer';

import logoMnu from './../../../../assets/imgs/logo-mnu.png';
import logoCycloid from './../../../../assets/imgs/logo-cycloid.png';

import OpenSansBold from './../../../../assets/fonts/OpenSans/OpenSans-Bold.ttf';
import OpenSansSemiBold from './../../../../assets/fonts/OpenSans/OpenSans-SemiBold.ttf';




// Register font
Font.register({ family: 'OpenSansBold', src: OpenSansBold });
Font.register({ family: 'OpenSansSemiBold', src: OpenSansSemiBold });


// Create styles
const styles = StyleSheet.create({
    pageA4: {
        display: "flex",
        flexDirection: 'row',
        backgroundColor: 'white'
    },
    viewRoot: {
        display: "flex",
        flexDirection: 'column',
        flexGrow: 1
    },
    viewRow: {
        display: "flex",
        flexDirection: 'row',
        flexGrow: 1
    },
    page: {
        display: "flex",
        flexDirection: 'row',
        backgroundColor: 'white'
    },
    rootContainer: {
        position: 'relative',
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexGrow: 1
    },
    boxLogo: {
        display: "flex",
        alignContent: 'center',
        alignItems: "center",
        alignSelf: "center",
        marginTop: 20
    },
    boxImageQrcode: {
        display: "flex",
        alignContent: 'center',
        alignItems: "center",
        alignSelf: "center",
        padding: 20,
        border: '1pt',
        borderColor: 'black',
        borderStyle: 'dotted',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        marginTop: 20
    },
    imageQrcode: {


    },
    boxTitle: {
        textAlign: 'center',
        display: "flex",
        alignSelf: "center",
        justifyContent: 'center',
        marginTop: 20,
        color: '#000000',
        maxWidth: 240,
        minWidth: 240,
        minHeight: 60,
    },
    title: {
        display: "flex",
        alignSelf: "center",
        justifyContent: 'center',
        fontFamily: 'OpenSansBold',
        fontSize: 17,
        textTransform: 'uppercase',
        lineHeight: 1.2,
        maxWidth: 240,
        minWidth: 240
    },
    boxSubtitle: {
        textAlign: 'center',
        display: "flex",
        alignContent: 'center',
        alignItems: "center",
        alignSelf: "center",
        marginTop: 20,
        color: '#000000',
        maxWidth: 180,
        minWidth: 180
    },
    subtitle: {
        fontFamily: 'OpenSansBold',
        fontSize: 14
    },
    boxFooter: {
        display: "flex",
        alignContent: 'center',
        alignItems: "center",
        alignSelf: "center",
        marginTop: 20
    },
    footerSubtitle: {
        fontSize: 6
    },
    boxLogoCyc: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 8
    },
    poweredBy: {
        fontSize: 6,
        marginRight: 5
    }
});

// Create Document Component
const DocumentPdf = ({
    qrCodeCanvas,
    title,
    description
}) => {

    const content =
        <>
            <View style={styles.rootContainer} >
                <View style={styles.boxLogo}>
                    {logoMnu ? <Image src={logoMnu} style={{ width: '68pt', height: '24pt' }} /> : null}
                </View>
                <View style={styles.boxTitle}>
                    <Text style={styles.title}>
                        {title}
                    </Text>
                </View>
                <View style={styles.boxSubtitle}>
                    <Text style={styles.subtitle}>{description}</Text>
                </View>
                {qrCodeCanvas ?
                    <View style={styles.boxImageQrcode}>
                        <Image src={qrCodeCanvas} style={{ width: 110, height: 110 }} />
                    </View> : null
                }
                <View style={styles.boxFooter}>
                    <View style={styles.boxLogoCyc}>
                        <Text style={styles.poweredBy}>
                            Powered by
                        </Text>
                        {logoCycloid ? <Image src={logoCycloid} style={{ width: '50pt', height: '12pt' }} /> : null}
                    </View>
                    <Text style={styles.footerSubtitle}>
                        www.cycloid.pt
                    </Text>
                </View>
            </View>
        </>



    return (
        <Document>
            <Page size="A4" style={styles.pageA4} >
                <View style={styles.viewRoot}>
                    <View style={styles.viewRow}>
                        {content}
                        {content}
                    </View>
                    <View style={styles.viewRow}>
                        {content}
                        {content}
                    </View>
                </View>
            </Page>
        </Document>
    );
}
export default DocumentPdf


