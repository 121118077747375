
export default theme => ({
    root: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        backgroundSize: "cover",
        backgroundColor: '#000000'
    },
    alertError: {
        marginBottom: '40px'
    },
    boxLogoCyc: {
        marginBottom: '38px'
    },
    boxTitleAndBack: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        color: '#FFFFFF',
        lineHeight: '24px',
        fontWeight: 'bold',
        fontSize: '35px',
        fontFamily: 'Open Sans',
        marginBottom: '42px',
        '& span': {
            textTransform: 'none',
        }
    },
    boxBack: {
        cursor: 'pointer',
        margin: '0px 0 10px',
        display: 'inline-flex',
        alignItems: 'center',
        '& p': {
            fontSize: '16px',
            lineHeight: '39px',
            color: '#FFFFFF'
        }
    },
    gridContainer: {
        height: '100%',
        padding: '50px'
    },
    form: {
        flexBasis: '520px',
        width: '100%'
    },
    boxButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginTop: '38px'
    },
    recoverPwdButton: {
        backgroundColor: '#D0212A',
        color: '#FFFFFF',
        boxShadow: '0px 1px 3px #000033',
        borderRadius: '5px',
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 600,
        marginBottom: '32px',
        width: '252px'
    }
});
