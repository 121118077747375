import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import {COLOR_PRIMARY, COLOR_TERTIARY} from './../../../../../../theme/colors';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';



/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';




// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }));

 
    // a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  
  const grid = 8;
  
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    color: 'white',
  
    // change background colour if dragging
    background: isDragging ? COLOR_TERTIARY : 'grey',
  
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'rgba(40, 40, 40, 0.2)' : "white",
    padding: grid,
    overflowY: 'auto'
  });

const AssetOrderList = ({
    classes,
    playListContents,
    sortAssets,
    close
}) => {
  
    const { t } = useTranslation();
    const [items, setItems] = useState([]);

    useEffect(() => {
       //const itns = playListContents.map(content => constent)
        setItems(playListContents);
    }, [playListContents])

    const saveOrderHandler = () => {
        sortAssets(items);
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const itemsSorted = reorder(
          items,
          result.source.index,
          result.destination.index
        );
    
        setItems( itemsSorted);
      }

  return (
    <>
    <Paper className={classes.paper}>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
                >
                {items.map((item, index) => (
                    <Draggable key={item.assets.id} draggableId={item.assets.id} index={index}>
                    {(provided, snapshot) => (
                        <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}
                        >
                        {item.assets.title}
                        </div>
                    )}
                    </Draggable>
                ))}
                {provided.placeholder}
                </div>
            )}
            </Droppable>
        </DragDropContext>
        <Box className={classes.boxActionButton}>
                <Button
                    className={classes.buttomCancel}
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => close()}
                >
                {t('cancel')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    onClick={() => saveOrderHandler()}
                >
                {t('save')}
                </Button>
            </Box>
    </Paper>
     
    </>
  )
}

AssetOrderList.propTypes = {
    classes: PropTypes.object,
    playListContents: PropTypes.array,
    sortAssets: PropTypes.func,
    close: PropTypes.func
}

export default compose(
    withStyles(styles)
  )(AssetOrderList);


