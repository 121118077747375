import React, {useState, useRef, createContext, useContext} from 'react';

import AlertDialog from '../../components/dialogs/AlertDialog/index.js';

const AlertDialogServiceContext = createContext(Promise.reject);

export const useAlertDialog = () => useContext(AlertDialogServiceContext);

export const AlertDialogServiceProvider = ({ children }) => { 

    const [confirmation, setconfirmation] = useState(null);

    const awaitingPromiseRef = useRef();

    const openConfirmation = (options) => {
        setconfirmation(options);
        return new Promise((resolve, reject) => {
          awaitingPromiseRef.current = { resolve, reject };
        });
      };

    const handleClose = () => {
    if (confirmation.catchOnCancel && awaitingPromiseRef.current) {
        awaitingPromiseRef.current.reject();
    }
    setconfirmation(null);
    };
    
    const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve();
    }
    setconfirmation(null);
    };

    return (
    <>
        <AlertDialogServiceContext.Provider
        value={openConfirmation}
        children={children}
        />

        <AlertDialog
        open={Boolean(confirmation)}
        agree={handleSubmit}
        disagree={handleClose}
        {...confirmation}
        />
    </>
    );
    

}