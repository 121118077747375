import React, {useState} from 'react'
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';



import { useTranslation } from 'react-i18next';

import logoMnu from './../../../assets/imgs/logo-mnu.svg';
import styles from './styles';

import {
    withStyles,
    Grid,
    FormControl,
    ThemeProvider,
    Typography,
    Dialog,
    DialogContent,
    InputAdornment,
    IconButton,
    Input,
    InputLabel,
    FormHelperText
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'


/* Components */
import SubmitButton from '../../../components/SubmitButton';
import PopupAuth from '../../../components/dialogs/PopupInfo';


import * as authService from './../../../services/AuthService/auth.service';
import authTheme from './../../../theme/authTheme';

const ResetPassword = ({
    classes
}) => {

    const history = useHistory();

    const [openPopup, setOpenPopup] = useState(false);
    const [statusPopup, setStatusPopup] = useState("");

    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    const { t } = useTranslation();

    const urlParams = new URLSearchParams(useLocation().search);
    const username = urlParams.get('username');
    const clientId = urlParams.get('appclient');
    const code = urlParams.get('code'); 



    const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: ''
        },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .required(t('required field'))
                .matches(
                    /^.*(?=.{8,}).*$/,
                    t('must contain 8 Characters')
                ),
            passwordConfirm: Yup.string()
                .required(t('required field'))
                .oneOf([Yup.ref('password'), null], t('passwords does not match'))

        }),
        onSubmit: async (values) => {
            const dataPost = {
                username: username,
                clientId: clientId,
                code: code,
                password: values.password
            }
            try {
                await authService.resetPasswordConfirm(dataPost)
                setStatusPopup('SUCCCESS_RESET_PASSWORD');
                setOpenPopup(true);
            } catch (error) {
               setStatusPopup('ERROR_RESET_PASSWORD');
               setOpenPopup(true);
            }
        }
    })

    const closePopupAuthHandler = () => {
        if (statusPopup === 'SUCCCESS_RESET_PASSWORD') {
            setStatusPopup('');
            setOpenPopup(false);
            history.push('/auth/login');
        } else {
            setStatusPopup('');
            setOpenPopup(false);
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(previous => !previous)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPasswordConfirm = () => {
        setShowPasswordConfirm(previous => !previous)
    };

    const handleMouseDownPasswordConfirm = (event) => {
        event.preventDefault();
    };


    const popup =
        <Dialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={openPopup}>
            <DialogContent>
                <PopupAuth
                    type={statusPopup}
                    close={closePopupAuthHandler}
                />
            </DialogContent>
        </Dialog>  

    
    return (
    <>
        <ThemeProvider theme={authTheme}>
            <div className={classes.root}>
                <Grid className={classes.gridContainer}
                    container
                    justify='center'
                    alignItems='center'
                    alignContent='center'>
                        <form onSubmit={formik.handleSubmit} className={classes.form} >

                            {!!formik.errors.resetPassword && (
                                <Alert
                                    className={classes.alertError}
                                    variant="filled"
                                    severity="error">{formik.errors.resetPassword}</Alert>
                            )}
                            <div className={classes.boxLogoCyc}>
                                <img width={117}
                                    height={44}
                                    alt="Logo" src={logoMnu} />
                            </div>
                            <Typography className={classes.title} variant="h1">
                                  {t('recover password')}
                            </Typography>
                            <FormControl fullWidth margin='normal'>
                                <InputLabel htmlFor="standard-adornment-password">{t('password')}</InputLabel>
                                <Input className={classes.inputField}
                                    autoFocus
                                    margin="dense"
                                    fullWidth
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.password && formik.touched.password}
                                    helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={classes.iconVisibility}
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                     }
                                />
                                {(formik.errors.password && formik.touched.password) && formik.errors.password && (
                                    <FormHelperText error>
                                        {formik.errors.password}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl fullWidth margin='normal'>
                                <InputLabel htmlFor="standard-adornment-password">{t('password confirm')}</InputLabel>
                                <Input
                                    className={classes.inputField}
                                    margin="dense"
                                    fullWidth
                                    id="passwordConfirm"
                                    type={showPasswordConfirm ? 'text' : 'password'}
                                    value={formik.values.passwordConfirm}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.passwordConfirm && formik.touched.passwordConfirm}
                                    helperText={(formik.errors.passwordConfirm && formik.touched.passwordConfirm) && formik.errors.passwordConfirm}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                className={classes.iconVisibility}
                                                onClick={handleClickShowPasswordConfirm}
                                                onMouseDown={handleMouseDownPasswordConfirm}
                                            >
                                                {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {(formik.errors.passwordConfirm && formik.touched.passwordConfirm) && formik.errors.passwordConfirm && (
                                    <FormHelperText error>
                                        {formik.errors.passwordConfirm}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <SubmitButton
                                className={classes.resetPasswordButton}
                                type="submit"
                                variant="contained"
                                loadingColor={"#FFFFFF"}
                                isSubmitting={formik.isSubmitting}>
                                {t('reset password')}
                            </SubmitButton>
                    </form>
                </Grid>
            </div>
        </ThemeProvider>
            { popup }
    </>

  )
}

ResetPassword.propTypes = {
    classes: PropTypes.func
}

export default (
    withStyles(styles)
)(ResetPassword)
