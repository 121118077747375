export default theme => ({
    root: {
      display: 'flex'
    },
    menu: {
        width: 250,
        marginTop: 16
    },
    buttonToogleMenu: {
        color: 'white'
    }
  });
