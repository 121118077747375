export default theme => ({

    root: {

    },
    form: {
        padding: 30
    },
    button: {
        position: "absolute",
        right: 10,
        top: 10
    },
    formControl: {
        margin: '20px auto',
        width: "100%"
    },
    inputText: {
        margin: '20px auto'
    }
});

