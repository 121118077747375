import * as actionsType from "../actions/actionsType";

export const layoutReducer = (state, action) => {
    switch (action.type) {
        case actionsType.LOADING:
            return {...state,  ...action.data}

        case actionsType.CLOSE_SNACKBAR:
            const snakbar = {
                show: false,
                message: '',
                severiry: ''
              }
            return {...state,  snakbar}

        case actionsType.OPEN_SNACKBAR:
            return {...state,  ...action.data}
            
        default:
            return state;
    }
}

