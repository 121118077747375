import React, {  } from 'react';

import './Loader.css';

const loader = () => {

    return (
        <div className = "Loader">
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );

}
export default loader;