import axiosInstance from '../../axios';
import axios from 'axios';


const MENUS = process.env.REACT_APP_MENUS;
const COMMERCE = 'commerce'
let MODULE_ID = '';
const ASSETS = 'assets';
const RESOURCE = 'resource';
const UPLOAD_URL = 'upload-url'
const UPLOAD = 'upload'
const LIST = 'list'




export const setModuleId = (productId) => {
    MODULE_ID = productId
}

/* Get Commerce Product Information */
export const getCommerceProduct = async () =>
    await axiosInstance.get(`${COMMERCE}/${MODULE_ID}`);

/* Update Commerce Product Information */
export const updateCommerceProduct = async (postData) =>
    await axiosInstance.put(`${COMMERCE}/${MODULE_ID}`, postData);

/* Get all menus from API */
export const getMenus = async () =>
    await axiosInstance.get(`${COMMERCE}/${MODULE_ID}/${MENUS}/list`);

/* Get  menu from API */
export const getMenu = async (id) =>
    await axiosInstance.get(`${COMMERCE}/${MODULE_ID}/${MENUS}/${id}`);

/* Get all menus from API */
export const createMenu = async (postData) =>
    await axiosInstance.post(`${COMMERCE}/${MODULE_ID}/${MENUS}/create`, postData);

/* Update an asset */
export const updateAsset = async (key, postData) =>
    await axiosInstance.put(`${ASSETS}/${MODULE_ID}/${RESOURCE}/${key}`, postData);

/* Delete an menu from API */
export const deleteMenu = async (id) =>
    await axiosInstance.delete(`${COMMERCE}/${MODULE_ID}/${MENUS}/${id}`);

/* Update an menu from API */
export const updateMenu = async (id, data) =>
    await axiosInstance.put(`${COMMERCE}/${MODULE_ID}/${MENUS}/${id}`, data);

/* Get an asset */
export const getAsset = async (key) =>
    await axiosInstance.get(`${ASSETS}/${MODULE_ID}/${RESOURCE}/${key}`);

export const getAssets = async (params) =>
    await axiosInstance.get(`${ASSETS}/${MODULE_ID}/${RESOURCE}/${LIST}`, { params: params });

/* Get a url to upload file */
export const getUploadFileUrl = async (fileInfo) => {
    return await axiosInstance.get(`${ASSETS}/${MODULE_ID}/${UPLOAD_URL}`, { params: { ...fileInfo } });

}

/* Upload file */
export const uploadFile = async (url, file, progressHandler) => {
    const config = { onUploadProgress: progressEvent => progressHandler(progressEvent) }
    return await axios.put(url, file, config);
}

/* Get all asset */
export const getImage = async (params) =>
    await axiosInstance.post(`${ASSETS}/${MODULE_ID}/download-url`, params);

/* Confirm  assets upload */
export const confirmUpload = async (postData) => 
    await axiosInstance.put(`${ASSETS}/${MODULE_ID}/${UPLOAD}/confirm`, postData)

