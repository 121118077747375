import axios from '../../axios';


const MODULE_ID = 'product-1';
const PLAYLIST =  process.env.REACT_APP_PLAYLIST;
const PLAYLIST_LIST = process.env.REACT_APP_PLAYLIST_LIST;
const PLAYLIST_CREATE = 'create';



/* Get all playlist from API */
export const getPlaylists = async () => await axios.get(`${PLAYLIST}/${MODULE_ID}/${PLAYLIST_LIST}`);

/* Get an playlist from API */
export const getPlaylist = async (id) => await axios.get(`${PLAYLIST}/${MODULE_ID}/${id}`);

/* Update an playlist from API */
export const updatePlaylist = async (id, data) => await axios.put(`${PLAYLIST}/${MODULE_ID}/${id}`, data);

/* Delete an playlist from API */
export const deletePlaylist = async (id) => await axios.delete(`${PLAYLIST}/${MODULE_ID}/${id}`);

/* Create an playlist from API */
export const createPlaylist = async (data) => await axios.post(`${PLAYLIST}/${MODULE_ID}/${PLAYLIST_CREATE}`, data);