import React, { useEffect, useContext } from 'react';



import  './assets/styles/index.css';


import Layout from './Layout';

import {Context}  from './context';
import MenuContextProvider from './context/MenuContext';


import Routes from './routes/routes';

import * as authService  from './services/AuthService/auth.service';
import LayoutContextProvider from './context/LayoutContext';




const App = () => {

  const { state, dispatch } = useContext(Context); 
  

  useEffect(() => {
    const checkAuthState = async () => {
      const userData = await authService.checkAuth();

      if (!userData) {
        const data = { data: null, isAuthenticated: false}
        const action = { type: 'AUTH_LOGOUT', payload: data };
        dispatch(action);
        dispatch({ type: 'AUTH_CHECKED', payload: true });
      } else {
        const data = { data: userData, isAuthenticated: true }
        const action = { type: 'AUTH_LOGIN', payload: data };
        dispatch(action);
        dispatch({ type: 'AUTH_CHECKED', payload: true });
      }
    }
    checkAuthState();
  }, [dispatch])


  const app = state.user.authChecked ? <LayoutContextProvider>
    <MenuContextProvider>
      <Layout>
        <Routes />
      </Layout>
    </MenuContextProvider>
  </LayoutContextProvider> : null
  

  
  return (
    <>
      {app}
    </>
   
  );
}

export default App;