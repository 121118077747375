import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Jimp from 'jimp';
import { useTranslation } from 'react-i18next';
import {
    withStyles,
} from '@material-ui/core'
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import DeleteIcon from '@material-ui/icons/Delete';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';

import styles from './styles';
import { LayoutContext } from "../../../context/LayoutContext.js";
import Loader from '../../Loader/Loader.js'


const RotateImageDialog = ({
    classes,
    file,
    imgBase64,
    saveImage,
    deleteImage,
    close
}) => {

    const { t } = useTranslation();
    const { layoutState, layoutDispatch } = useContext(LayoutContext);
    const [image, setImage] = useState(null)
    const [offset, setOffset] = useState(0);
    const [base64] = useState(imgBase64);
    

  
    useEffect(() => {
       processRotate(imgBase64, offset)
      return () => {
          //
      }
    
  }, [])
    
    const onRotateRight = async () => {
        layoutDispatch({ type: 'LOADING', data: { loading: true } });
        setOffset(previous => previous - 90)
        const image = await Jimp.read(base64);
        image.rotate(offset - 90).getBase64(Jimp.MIME_JPEG, function (err, src) {
            setImage(src)
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        })
    }

    const onRotateLeft = async () => {
        layoutDispatch({ type: 'LOADING', data: { loading: true } });
        setOffset(previous => previous + 90)
        const image = await Jimp.read(base64);
        image.rotate(offset + 90).getBase64(Jimp.MIME_JPEG, function (err, src) {
            setImage(src)
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        })
    }

  
    const processRotate = async (buf, angle) => {
        layoutDispatch({ type: 'LOADING', data: { loading: true } });
        const image = await Jimp.read(buf);
        image.rotate(angle).getBase64(Jimp.MIME_JPEG, function (err, src) {
            setImage(src)
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        })
    }
    
    const saveHandler = () => {
        saveImage(file, image )
    }

    const removeImage = () => {
        deleteImage(file)
    }

    
    return (
        <>
            {
            image ? <div className={classes.root}>
                <div className={classes.boxButton}>
                        <RotateLeftIcon onClick={onRotateLeft} />
                        <RotateRightIcon onClick={onRotateRight} />
                        <DeleteIcon onClick={() => removeImage(file)} />
                        <CloseSharpIcon className="closeIcon" onClick={() => close()} />
                </div>
                <div className={classes.imageBox}>
                    <img src={image} />
                </div>
                    <div className={classes.footerBox}>
                        <p onClick={saveHandler}>{t('record')}</p>
                </div>
                
                </div> : null
            }    
            {layoutState.loading ? <Loader /> : null}
            </>
  )
}



RotateImageDialog.propTypes = {
    classes: PropTypes.object,
    imgBase64: PropTypes.any,
    file: PropTypes.any,
    saveImage: PropTypes.func,
    deleteImage: PropTypes.func,
    close: PropTypes.func
}

export default compose(
    withStyles(styles),
)(RotateImageDialog)

