export default theme => ({
    drawer: {
    width: 280,
    [theme.breakpoints.up('lg')]: {
      marginTop: 75,
      height: 'calc(100% - 75px)'
    },
    },
    root: {
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: '16px'
    },
    divider: {
      margin: '16px 0px',
      backgroundColor: '#eeeeee'
    }    
  });
  
