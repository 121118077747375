export default theme => ({
    textField: {
        marginBottom: '20px'
    },
    dialogContent: {
        padding: '10px 32px'
    },
    inputField: {
        paddingTop: '10px',
        paddingBottom: '10px'
    }
  });