
export default theme => ({
    root: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        backgroundSize: "cover",
        backgroundColor: '#000000'
    },
    alertError: {
        marginBottom: '40px'
    },
    boxLogoCyc: {
        marginBottom: '38px'
    },
    title: {
        color: '#FFFFFF',
        lineHeight: '24px',
        fontWeight: 'bold',
        fontSize: '37px',
        textTransform: 'uppercase',
        fontFamily: 'Open Sans',
        marginBottom: '42px',
        '& span': {
            textTransform: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    gridContainer: {
        height: '100%',
        padding: '50px'
    },
    form: {
        flexBasis: '520px',
        width: '100%'
    },
    boxButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginTop: '38px'
    },
    resetPasswordButton: {
        backgroundColor: '#D0212A',
        color: '#FFFFFF',
        boxShadow: '0px 1px 3px #000033',
        borderRadius: '5px',
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 'bold',
        marginBottom: '32px',
        marginTop: '38px',
        width: '170px'
    },
    iconVisibility: {
        color: 'rgba(255, 255, 255, 0.3)'
    },
    inputField: {
        paddingTop: '5px',
        paddingBottom: '5px'
    }
});
