import React, {useState, useContext, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './styles';


/* Material-UI */
import { withStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

/* Components */
import ProfileMenu from './components/ProfileMenu';
import AccountDialog from '../../../../../components/dialogs/AccountDialog';
import PasswordRequiredDialog from '../../../../../components/dialogs/PasswordRequiredDialog';

/* Context */
import { LayoutContext } from "../../../../../context/LayoutContext";
import { Context } from "../../../../../context";


/* Contants */
import * as STATUS_CODE from '../../../../../constants/httpCode';
import * as actionTypes from '../../../../../actions/actionsType';

/* Services */
import * as authService  from '../../../../../services/AuthService/auth.service';


const Profile = ({
  classes,
  user
}) => {

    const history = useHistory();

    const {layoutDispatch} = useContext(LayoutContext); 
    const {state} = useContext(Context); 

    const [openProfileMenu, setOpenProfileMenu] = useState(false);
    const [openAccountDialog, setOpenAccountDialog] = useState(false);
    const [passwordRequired, setPasswordRequired] = useState(false);
    const [accountToSwitch, setAccountToSwitch] = useState(null);
    const [accounts, setAccounts] = useState(null);

    const anchorRef = useRef(null);


    const loading = (load) => {
      layoutDispatch({ type: actionTypes.LOADING, data: {loading: load}});
  }
    

    const handleToggle = () => {
      setOpenProfileMenu((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpenProfileMenu(false);
    };

     /* Switch account handler  */
    const switchAccountHandle = () => {
      loading(true);
      authService.getUserTenants().then( resp => {
          loading(false);
          setAccounts(resp);
          setOpenProfileMenu(false);
          setOpenAccountDialog(true);
      }
    ).catch(error => {
      loading(false);
    });
  }

    const accountSelectHandler = (account) => {
      setAccountToSwitch(account);
      setOpenAccountDialog(false);
      setPasswordRequired(true);
    }

    /* Switch account */
    const switchAccount = (password) => {
      setPasswordRequired(false);
      loading(true);
      const email = user.data.email;
      const tenantId = accountToSwitch.id;
      authService.login(email, password, tenantId ).then(
        resp => {
            loading(false);
            if (resp) {
                switch (resp.code) {
                  case STATUS_CODE.SUCCESSFULLY_LOGIN: {
                    const data = { user: resp.data.data, isAuthenticated: true };
                    const action = { type: 'AUTH_LOGIN', payload: data };
                    showSnackbar("success", "Account succcessfuly switched");
                    state(action);
                    history.push('/');
                    break;
                  }
                  default:
                      break;
                }
            }
        }
    );
  }

  const showSnackbar = (severiry, message) => {
    const snakbar = {
        show: true,
        message: message,
        severiry: severiry
      }
      layoutDispatch({ type: actionTypes.OPEN_SNACKBAR, data: {snakbar: snakbar}});
}


 
   const passwordRequiredDialogRender = passwordRequired ?
           <PasswordRequiredDialog
              closeDialog={() => setPasswordRequired(false)}
              confirm={(password) => switchAccount(password)}/>
           : null;


   const accountDialogRender = openAccountDialog ? 
           <AccountDialog 
               tenants={accounts}
               accountSeleted={accountSelectHandler} 
               closeDialog={() => {
                                 setOpenAccountDialog(false);
                                 setAccounts(null)
               }}/> 
            :  null;
  

   return (
     <>
       <div className={classes.root}>
            <Avatar
               alt="Person"
               className={classes.avatar}
          />


         { /* 
         
                      <IconButton onClick={handleToggle} ref={anchorRef}
            className={classes.menuButton}
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
           >
             <MoreVertIcon />
           </IconButton>
         */}


           <Typography
             className={classes.name}
             variant="h4"
           >
           {user.data.tenantName}
      </Typography>
      <Typography variant="body2">{user.data.email}</Typography>

      <ProfileMenu 
         anchorRef={anchorRef}
         open={openProfileMenu}
         switchAccount={switchAccountHandle}
         handleClose={(event)=> handleClose(event)}/>
    </div>

       {accountDialogRender}

       {passwordRequiredDialogRender}
     </>  
   );
}

export default compose(
  withStyles(styles)
)(Profile);