import React, { useContext}  from 'react';
import PropTypes from 'prop-types';

/* Material-UI */
import {ThemeProvider } from "@material-ui/core/styles";

/* Components */
import theme from './../theme';
import Loader from '../components/Loader/Loader';
import Main from './components/Main';


/* Constantes */
import * as actionTypes from '../actions/actionsType';

/* Contexts */
import { Context } from '../context';
import { LayoutContext } from '../context/LayoutContext';
import { AlertDialogServiceProvider } from '../services/AlertDialogService/alertDialog.service.js';

/* Components */
import SnackbarDialog from '../components/dialogs/SnackbarDialog';




const Layout = props => {
 
   const { state } = useContext(Context);

   const { layoutState, layoutDispatch } = useContext(LayoutContext);



  
    const snackbarHandleClose = () => {
      const action = { type: actionTypes.CLOSE_SNACKBAR};
      layoutDispatch(action);
    }



    const loader = layoutState.loading ? <Loader/> : null;
    const snakbarRender = layoutState.snakbar.show ?
            <SnackbarDialog 
             {...layoutState.snakbar} 
             snackbarHandleClose={snackbarHandleClose}/> 
             : null;

    return (
        <>   
           <ThemeProvider theme={theme}>
              <AlertDialogServiceProvider>
                  <Main {...state}>
                        {props.children}
                  </Main>            
                   {loader}
                   {snakbarRender}

              </AlertDialogServiceProvider>
           </ThemeProvider>
        </>
    );
}
 
export default Layout;

Layout.propTypes = {
   children: PropTypes.node
 };

  