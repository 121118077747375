const icons = {
    user: require('../assets/icons/icon-user.png'),
    arrow: require('../assets/icons/icon-arrow.png'),
    admin: require('../assets/icons/icon-admin.png'),
    menuHamburger: require('../assets/icons/icon-menu-hamburger.png'),
    add: require('../assets/icons/icon-add.png'),
    exportExcel: require('../assets/icons/icon-export-excel.png'),
    logout: require('../assets/icons/icon-logout.png'),
    edit: require('../assets/icons/icon-pencil.svg'),
    trash: require('../assets/icons/icon-trash.svg'),
    info: require('../assets/icons/icon-info.svg'),
    alert: require('../assets/icons/icon-alert.svg'),
    flagPortugues: require('../assets/icons/icon-flag-portugues.png'),
    flagEnglish: require('../assets/icons/icon-flag-english.png')
}

export default icons;