import React, {createContext, useReducer, useEffect} from "react";
import {layoutReducer} from "../reducers/layoutReducer";

/* Components */


export const LayoutContext = createContext();

const initialState = {
    loading: false,
    snakbar: {
        show: false,
        message: '',
        severiry: 'success'
    }
}

const LayoutContextProvider = (props) => {

    const [layoutState, layoutDispatch]  = useReducer(layoutReducer, initialState);
    const value = {layoutState, layoutDispatch};


 

    return  (
        <>
        <LayoutContext.Provider value={value} showSnackbar>
            {props.children}
        </LayoutContext.Provider>
        </>
    )
}

export default LayoutContextProvider;
