export default theme => ({
    root: {
        margin: 'auto',
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      },
      cardHeader: {
        padding: theme.spacing(1, 2),
      },
      list: {
        width: 200,
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
      },
      button: {
        margin: theme.spacing(0.5, 0),
      },
      boxActionButton: {
          padding: '20px 0'
      }
  });
