import React, { useEffect, useContext, useReducer, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import styles from './styles';

/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';


/* Components */
import PlaylistInfoTable from './components/PlaylistInfoTable';
import TableData from './../../../../components/TableData';
import AssetTransferList from '../AssetTransferList';
import AssetOrderList from './components/AssetOrderList';
import SwitchButton from './../../../../components/SwitchButton';
import PlaylistInfoForm from './components/PlaylistInfoForm';

/* Contexts */
import {LayoutContext} from '../../../../context/LayoutContext';

/* Services */
import * as playlistService from '../../../../services/playlistService/playlist.service.js';
import * as assetService from '../../../../services/AssetService/asset.service';
import {useAlertDialog} from '../../../../services/AlertDialogService/alertDialog.service.js';





const initialState = {
    playlist: {},
    assetsDataTable: [],
    allAssets: [],
    openFormAddAssets: false,
    openAssetReOrder: false,
    openFormEditInfo: false
};

/* REDUCER */
const reducer = (state, action) => {
    switch (action.type) {
        case "SET_PLAYLIST":
            return {
                ...state,
                playlist: action.payload
            }
        case "SET_DATA_TABLE":
            return {
                ...state,
                assetsDataTable: action.payload
            }
        case "SET_FORM_ADD_ASSETS":
            return {
                ...state,
                openFormAddAssets: !state.openFormAddAssets
            }
        case "SET_ALL_ASSETS":
            return {
                ...state,
                allAssets: action.payload
            }
        case "SET_REORDER":
            return {
                ...state,
                openAssetReOrder: !state.openAssetReOrder
            }
        case "SET_FORM_EDIT_INFO":
            return {
                ...state,
                openFormEditInfo: !state.openFormEditInfo
            }
        default:
            return state;
    }
}



const PlaylistManagement = ({
    classes
}) => {

    const history = useHistory();
    const { t } = useTranslation();
    const { playlistId } = useParams();
    const {layoutDispatch} = useContext(LayoutContext); 
    const [state, dispatch] = useReducer(reducer, initialState);
    const alertDialog = useAlertDialog();


    let columnsAssets = [
        { title: t('order'), field: 'order' },
        { title: t('title'), field: 'title' },
        { title: t('type'), field: 'type' },
        {
            field: 'status',
            title: t('enabled'),
            // eslint-disable-next-line react/display-name
            render: rowData =>
                <SwitchButton
                    checked={rowData.enable}
                    onChange={(enable) => {
                        enableAssetHandler(rowData.id, enable)
                }} />
        }];



    const actionsAssets = [
        {
            icon: 'delete',
            tooltip: t('delete'),
            onClick: (event, rowData) => { deleteAssetsHandler(rowData) }
        },
        {
            icon: 'add',
            tooltip: t('add asset'),
            isFreeAction: true,
            onClick: () => {
                addAssetsHandler()
            }
        },
        {
            icon: 'sort',
            tooltip: t('sort'),
            isFreeAction: true,
            onClick: () => {
                dispatch({ type: 'SET_REORDER' });
            }
        }
    ]



 
    const enableAssetHandler = (assetId, enable) => {
        const string = enable ? 'enable' : 'disable';
        const dataAlert = {
            title: t('assets playlist'),
            info: t(`Are you sure of ${string} this asset?`)
        }
        alertDialog({
            ...dataAlert
        })
        .then( () => {
            const playlistContent = [...state.playlist.content];
            const playlistContentUpdated = playlistContent.map(content => {
                if (content.assets.id === assetId) {
                    content.enable = enable;
                    return content
                }
                return content
            });
            const playlistUpdated = {
                imageDefaultTime: state.playlist.image_default_time,
                content: playlistContentUpdated,
                title: state.playlist.title
            }
            updatePlaylist(state.playlist.playlist_id, playlistUpdated);
        })
        .catch(() => { })
    }

  


    useEffect(() => {
        const getPlaylist = async () => {
            layoutDispatch({ type: 'LOADING', data: { loading: true } });
            try {
                const response = await playlistService.getPlaylist(playlistId);
                layoutDispatch({
                    type: 'LOADING',
                    data: { loading: false }
                });
                dispatch({
                    type: "SET_PLAYLIST",
                    payload: response.data
                })
                dispatch({
                    type: "SET_DATA_TABLE",
                    payload: setDataTableAssets(response.data.content)
                })
            } catch (error) {
                layoutDispatch({ type: 'LOADING', data: { loading: false } });
            }
        };

        getPlaylist();
    }, [layoutDispatch, playlistId]);


  
    

    const updatePlaylist = async (id, data) => {
        try {
            layoutDispatch({type: 'LOADING', data: {loading: true}});
            const response = await playlistService.updatePlaylist(id, data);
            dispatch({
                type: "SET_PLAYLIST",
                payload: response.data.data.Attributes
            })
            dispatch({
                type: "SET_DATA_TABLE",
                payload: setDataTableAssets(response.data.data.Attributes.content)
            })
            layoutDispatch({type: 'LOADING', data: {loading: false}});
        } catch (error) {
            layoutDispatch({type: 'LOADING', data: {loading: false}});
        }
    }


    
    const formAddAssetsCloseHandle = async () => {
        dispatch({type: "SET_FORM_ADD_ASSETS"});
    }


    const addAssetsHandler = async () => {
        layoutDispatch({ type: 'LOADING', data: {loading: true}});
        try {
            const response = await assetService.getAsset();
            dispatch({
                type: "SET_ALL_ASSETS",
                payload: response.data.data.items
            })
            dispatch({type: "SET_FORM_ADD_ASSETS"});
            layoutDispatch({ type: 'LOADING', data: {loading: false}});
        } catch (error) {
            layoutDispatch({ type: 'LOADING', data: {loading: false}});
        }
    }

    const deleteAssetsHandler = (asset) => {
        const dataAlert = {
            title: t('remove asset from playlist'),
            info: t('are you sure of remove this asset')
            }
            alertDialog({
            ...dataAlert
            })
            .then(() => {
                const playlistContent = [...state.playlist.content];
                const playlistContentUpdated = playlistContent.filter(content =>  content.assets.id !== asset.id);
                const playlistUpdated = {
                    imageDefaultTime: 61,
                    content: playlistContentUpdated,
                    title: state.playlist.title
                }
               updatePlaylist(state.playlist.playlist_id, playlistUpdated );
            })
            .catch(() => {})
    }

    const saveAssetsToPlaylist = async (assetsToAdd) => {
        dispatch({type: "SET_FORM_ADD_ASSETS"});
        const contentToAdd = assetsToAdd.map(asset =>  {
            return {
                enable: true,
                assets: {
                    id: asset.id,
                    type: asset.type,
                    createdby: asset.created_by,
                    bucket_key: asset.bucket_key,
                    title: asset.title
                }
            }
        })
        const playlistUpdated = {
            imageDefaultTime: 61,
            content: [...state.playlist.content, ...contentToAdd],
            title: state.playlist.title
        }
        updatePlaylist(state.playlist.playlist_id, playlistUpdated );
    }

   

    const sortAssetsHander = (itemsSorted) => {
        const dataAlert = {
            title: t('sort asset in playlist'),
            info: t('are you sure of change the assets order in this playlist')
            }
            alertDialog({
            ...dataAlert
            })
            .then(() => {
                dispatch({ type: 'SET_REORDER'})
                const playlistUpdated = {
                    imageDefaultTime: state.playlist.image_default_time,
                    content: itemsSorted,
                    title: state.playlist.title
                }
               updatePlaylist(state.playlist.playlist_id, playlistUpdated );
            })
            .catch(() => {})
    }

    const backHandlaer = () => {
        history.goBack();
    }

    const editInfoHandler = () => {
        dispatch({ type: 'SET_FORM_EDIT_INFO' });
    }

    const updateInfoPlaylist = (data) => {
        dispatch({ type: 'SET_FORM_EDIT_INFO' });
        const playlistUpdated = {
            imageDefaultTime: state.playlist.image_default_time,
            content: state.playlist.content,
            title: data.title
        }
        updatePlaylist(state.playlist.playlist_id, playlistUpdated);
    }

    let assetsTableRender = state.assetsDataTable ? 
        <Box className={classes.boxTable}>
            <TableData
                columns={columnsAssets}
                data={state.assetsDataTable}
                actions={actionsAssets}
                title=  "Playlist assets"
            />
        </Box>
        : null; 


    


    

    return (
        <>
         <Container 
            className={classes.root}
            maxWidth={false}
            >
            <Typography variant="h4">
                {state.playlist.title}
            </Typography>
            <Box className={classes.boxHeader}>
               <Button
                    size="small"
                    variant="contained" 
                    color="primary"
                    onClick= {() => backHandlaer()}
                    >
                    {t('back')}
               </Button>
            </Box>
            <Grid
                container
                spacing={4}
                >
                <Grid xs={12} sm={12} md={4} lg={4} xl={4}
                    item
                    >
                    <Card className={classes.cardInfo}>
                        <CardHeader
                                title={state.playlist.title}
                                action={
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() => editInfoHandler()}>
                                        <EditIcon />
                                    </IconButton>
                                }
                            />
                               
                        <Divider light />
                        <CardContent>
                          <PlaylistInfoTable playlist={state.playlist}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} sm={12} md={8} lg={8} xl={8}
                    item
                    >
                    {assetsTableRender}
                </Grid>        
            </Grid>    
         </Container>

        <Dialog
            open={state.openFormAddAssets} 
            onClose={formAddAssetsCloseHandle}
            maxWidth="md"
            fullWidth
        >
           <DialogContent>
                <DialogContentText id="dialog">
                        {t('chosse assets to add to playlist')}
                </DialogContentText>
                <AssetTransferList
                        assets={state.allAssets}
                        playlist={state.playlist}
                        cancel={() => dispatch({type: "SET_FORM_ADD_ASSETS"})}
                        addAssetsHandler={(assetsToAdd) => saveAssetsToPlaylist(assetsToAdd)}
                    />
            </DialogContent>
        </Dialog>

        <Dialog
            open={state.openAssetReOrder} 
            onClose={() => dispatch({ type: 'SET_REORDER'})}
            maxWidth="md"
            fullWidth
        >
           <DialogContent>
                <DialogContentText id="dialog">
                        {t('drag assets to reorder in playlist')}
                </DialogContentText>
                <AssetOrderList
                    playListContents={state.playlist.content}
                    sortAssets={(itemsSorted) => sortAssetsHander(itemsSorted)}
                    close={() => dispatch({ type: 'SET_REORDER'}) }
                    />
            </DialogContent>
        </Dialog>
        
            <Dialog open={state.openFormEditInfo}
                onClose={() => dispatch({ type: 'SET_FORM_EDIT_INFO' })}
                maxWidth="md"
                fullWidth>
                <DialogContent>
                    <PlaylistInfoForm
                        playlist={state.playlist}
                        onClose={() => dispatch({ type: 'SET_FORM_EDIT_INFO' })}
                        onSave={(data) => updateInfoPlaylist(data)}/>
                </DialogContent>
        </Dialog>
        
        </>
    );
}

export default compose(
    withStyles(styles)
  )(PlaylistManagement);


const setDataTableAssets = (contents) => {
    return contents.map((content, index) => {
        return {
            order: index + 1,
            id: content.assets.id,
            type: content.assets.type,
            title: content.assets.title,
            enable: content.enable
        }
    })
}


