export default theme => ({
    toolbar: {
        display: 'flex',
        '& .MuiToolbar-root': {
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            margin: '20px auto auto',
            '& .MuiFormControl-root': {
                paddingLeft: 0
            }
        }
    }, 
    action: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
       justifyContent: 'center'
    }
})