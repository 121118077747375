import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose';
import styles from './styles';

/* Material-UI */
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';  
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';

import { Context } from '../../../context';


const AccountDialog = ({
  classes,
  accountSeleted,
  tenants,
  email,
  closeDialog
}) => {

  const { t } = useTranslation();

  const { state } = useContext(Context);

  
  email = state.user.isAuthenticated ? state.user.data.email : email;


  /* Call function to close Dialod */
  const handleClose = () => {
    closeDialog();
  }

  /* Call function to handler account seleted */
  const handleListItemClick = (tenant) => {
    accountSeleted(tenant);
  };

    
    return (
        <Dialog maxWidth="xs" fullWidth
         onClose={handleClose} 
         aria-labelledby="simple-dialog-title"
         open={true}>

        <DialogContent className={classes.root}>
          <div className={classes.dialogTitle}>
            <Avatar
                alt="Person"
                className={classes.avatarCurrent}
              />
            <Typography variant="h6">{email}</Typography>
          </div> 
          <Divider className={classes.divider}/>
           {
             !state.user.isAuthenticated ? 
              <div className={classes.Info}>
                    <Typography variant="h4"></Typography>  
                      <Box fontWeight="fontWeightBold" m={1}>
                        {t('you have multiple account')}
                      </Box>
                      <Typography variant="h5"></Typography>  
                        <Box fontWeight="fontWeightBold" m={1}>
                         
                         {t('please choose an account')}
                        </Box>                    
             </div>
             : null
           }
          <List className={classes.list}>
          {tenants.map((tenant) => (
            <ListItem 
              className={classes.listItem}
              button
              onClick={() => handleListItemClick(tenant)} key={tenant.id}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={tenant.name} />
            </ListItem>
        ))}
         </List>
         </DialogContent>    
      </Dialog>
    );

}

export default compose(
  withStyles(styles)
)(AccountDialog);

