import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose';
import styles from './styles';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';




/* Material-UI */
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';




const validationSchema = Yup.object().shape({
    title: Yup.string()
        .required('Required')
});



const PlaylistInfoForm = ({
    classes,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    palylist,
    onClose,
    onSave
}) => {

    const { t } = useTranslation();

  return (
    <>
          <form onSubmit={handleSubmit} className={classes.form}>
              <IconButton className={classes.button} onClick={onClose}>
                  <CloseIcon />
              </IconButton>
              <Grid container spacing={4} direction="column" justify="center">
                  <Typography variant="h5">
                      {t('playlist')}
                </Typography>

                  <Grid item>
                      <Grid item>
                          <FormControl className={classes.formControl}>
                              <TextField
                                  className={classes.inputText}
                                  id="title"
                                  fullWidth
                                  label={t('Title')}
                                  autoComplete="off"
                                  value={values.title}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.title && touched.title}
                                  helperText={(errors.title && touched.title) && errors.natitleme}
                              />
                          </FormControl>
                      </Grid>
                      <Grid container justify="flex-end" spacing={2}>
                          <Grid item>
                              <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => onClose()}
                              >
                                  {t('cancel')}
                              </Button>
                          </Grid>
                          <Grid item>
                              <Button type="submit" variant="contained" color="primary">
                                  {t('save')}
                              </Button>
                          </Grid>
                      </Grid>
                  </Grid>
              </Grid>
          </form>
    </>
  )
}

PlaylistInfoForm.propTypes = {

}

export default compose(
    withStyles(styles),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: ({ playlist }) => {
            return {
                title: playlist.title
            }
        },
        validationSchema: validationSchema,
        handleSubmit: (values, { props: { onSave } }) => {
            onSave(values);
        }
    })
)(PlaylistInfoForm)
