import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable, {
    MTableToolbar,
    MTableActions,
    MTableHeader
} from 'material-table';
import { useTranslation } from 'react-i18next';
import styles from './styles';

/* Material-UI */
import {
    withStyles
} from '@material-ui/core';


const TableData = ({
    classes,
    columns,
    data,
    actions,
    title
}) => {
    
    const { t } = useTranslation();

    return (
        <MaterialTable
            title={title}
            columns={columns}
            data={data}
            actions={actions}
            components={{
                Toolbar: props => (
                    <div className={classes.toolbar}>  
                        <MTableToolbar {...props} />
                    </div>
                ),
               Actions: props => (
                    <div className={classes.action}>
                        <MTableActions {...props} />
                    </div>
                    
                ),
                Header: props => (
                        <MTableHeader {...props} />
                )
            }}
            options={{
                actionsColumnIndex: -1,
                actionsCellStyle: {
                    justifyContent: 'center'
                    
                },
                searchFieldStyle: {
                    marginRight: '20px',
                    paddingLeft: 0
                },
                exportButton: true,
                pageSizeOptions: [8, 16, 30],
                pageSize: 8
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: t('empty data source'),
                    addTooltip: t('add'),
                    deleteTooltip: t('delete'),
                    editTooltip: t('edit')
                },
                header: {
                    actions: t('actions')
                },
                toolbar: {
                    searchTooltip: t('search'),
                    searchPlaceholder: t('search')
                },
                pagination: {
                    labelRowsSelect: t('rows'),
                    labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
                    firstTooltip: t('first page'),
                    previousTooltip: t('previous page'),
                    nextTooltip: t('next page'),
                    lastTooltip: t('last page')
                }
            }}
        />
    )
}
TableData.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    actions: PropTypes.array,
    title: PropTypes.string
  };

export default (
    withStyles(styles)
)(TableData)

