import React from 'react';
import { useTranslation } from 'react-i18next';
import { compose } from 'recompose';
import styles from './styles';
import { withFormik, Field } from 'formik';
import { SimpleFileUpload } from 'formik-material-ui';
import * as Yup from 'yup';
import { PropTypes } from "prop-types";

/* Material-UI */
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';



const FILE_SIZE = 101600000000000000000 * 1024;
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "video/mp4"
];

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required')
});



const AssetForm = ({
    classes,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    asset,
    onClose,
    onSave,
    onUpdate,
    updating

}) => {

    const { t } = useTranslation();

    return (
        <>
            <form onSubmit={handleSubmit} className={classes.form}>
                <IconButton className={classes.button} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Grid container spacing={4} direction="column" justify="center">
                    <Typography variant="h5">
                        {t('add an asset')}
                </Typography>

                    <Grid item>
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    className={classes.inputText}
                                    id="name"
                                    fullWidth
                                    label={t('name')}
                                    autoComplete="off"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.name && touched.name}
                                    helperText={(errors.name && touched.name) && errors.name}
                                />
                            </FormControl>
                        
                            
                            <FormControl>
                                <Field
                                    component={SimpleFileUpload}
                                    name="file"
                                    label="File to upload"
                                    value={values.file}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />;
                            </FormControl>
                        </Grid>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => onClose()}
                                >
                                    {t('cancel')}
                             </Button>
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="contained" color="primary">
                                    {t('save')}
                               </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};


AssetForm.propTypes = {
    classes: PropTypes.object,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    asset: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
}


export default compose(
    withStyles(styles),
    withFormik({
        enableReinitialize: true,
        mapPropsToValues: ({ asset, updating }) => {
            let values = {};
            if (updating) {
                values = {
                    name: asset.name,
                    file: null
                }
            } else {
                values = {
                    name: '',
                    file: null
                }
            }
            return values;
        },
        validationSchema: validationSchema,
        handleSubmit: (values, { props: { onUpdate, onSave, updating, } }) => {
            updating ? onUpdate(values) : onSave(values)
            
        }
    })

)(AssetForm);

/*
file: Yup
    .mixed()
    /.test(
        "fileSize",
        "File too large",
        value => value && value.size <= FILE_SIZE
    )
        .test(
            "fileFormat",
            "Unsupported Format",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        )
        */