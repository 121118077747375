// NATIVE IMPORTS
import React, { useEffect, useState } from 'react';
import { PropTypes } from "prop-types";
import { compose } from 'recompose';
// MODULES IMPORTS
import { withStyles, Typography, Container, FormControl, TextField, Grid, Button, Zoom } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// STYLE IMPORTS
import styles from './styles';
import './style.css';
// COMPONENT IMPORTS

// SERVICES IMPORTS
import * as ementaService from '../../../services/ementaService/ementa.service';

import { useParams } from 'react-router-dom';


const EmentaConfigurations = ({ classes }) => {

    const { t } = useTranslation();
    const { productId } = useParams();
    const [productInfo, setProductInfo] = useState({ name: '', email: '', phone: '', address: '', zipcode: '', locale: '' });
    const [editMode, setEditMode] = useState(false);


    useEffect(() => {
        ementaService.setModuleId(productId);
        getProductInfo();
    }, []);

    const getProductInfo = async () => {
        const response = await ementaService.getCommerceProduct();
        // console.log(response);
        const data = {
            ...response.data.info,
            name: response.data.name,
            locale: response.data.locale
        }
        // console.log(data);
        setProductInfo(data);
    }

    const updateProductInfo = async (data) => {
        const response = await ementaService.updateCommerceProduct(data);
        if (response.status === 200) {
            setTimeout(async () => {
                await getProductInfo();
                setEditMode(false);
            }, 1500);
        }
    }

    return (
        <Container className={classes.root} maxWidth={false}>
            <Typography variant="h3">
                {t('product configurations')}
            </Typography>
            <div className={classes.container}>
                <div className={classes.boxContent}>
                    <div className={classes.boxSection}>
                        <div className={classes.boxSectionTitle}>{t('information')}</div>
                        {
                            editMode ?
                                <ConfigurationWriteView style={classes} productInfo={{ get: productInfo, set: setProductInfo }} onSubmitForm={(e) => { e.preventDefault(); updateProductInfo(productInfo) }}>
                                    <ActionContext config={[
                                        {
                                            value: 'save',
                                            color: 'primary',
                                            type: 'submit'
                                        },
                                        {
                                            value: 'cancel',
                                            onClick: () => { setEditMode(false) },
                                            color: 'secondary',
                                            type: null
                                        }
                                    ]}
                                        style={classes} />
                                </ConfigurationWriteView>
                                :
                                <ConfigurationReadView style={classes} productInfo={{ get: productInfo, set: setProductInfo }}>
                                    <ActionContext config={[
                                        {
                                            value: 'edit',
                                            onClick: () => { setEditMode(true) },
                                            color: 'primary',
                                            type: null
                                        }
                                    ]}
                                        style={classes} />
                                </ConfigurationReadView>
                        }
                    </div>
                </div>
            </div>
        </Container>
    );
}

EmentaConfigurations.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(EmentaConfigurations)


const ConfigurationReadView = (props) => {

    const { t } = useTranslation();

    return (
        <>
            <div className={props.style.boxSectionContainer}>
                <div className={props.style.boxSectionContainerColumn}>
                    <div className={props.style.boxSectionContainerColumnRow}>
                        <p className={props.style.columnRowLabel}>{t('name')}</p>
                        {props.productInfo.get.name === '' ? <p className={props.style.columnRowValueEmpty}></p> : <p className={props.style.columnRowValue}>{props.productInfo.get.name}</p>}
                    </div>
                    <div className={props.style.boxSectionContainerColumnRow}>
                        <p className={props.style.columnRowLabel}>{t('email')}</p>
                        {props.productInfo.get.name === '' ? <p className={props.style.columnRowValueEmpty}></p> : <p className={props.style.columnRowValue}>{props.productInfo.get.email}</p>}
                    </div>
                    <div className={props.style.boxSectionContainerColumnRow}>
                        <p className={props.style.columnRowLabel}>{t('phone')}</p>
                        {props.productInfo.get.name === '' ? <p className={props.style.columnRowValueEmpty}></p> : <p className={props.style.columnRowValue}>{props.productInfo.get.phone}</p>}
                    </div>
                    <div className={props.style.boxSectionContainerColumnRow}>
                        <p className={props.style.columnRowLabel}>{t('address')}</p>
                        {props.productInfo.get.name === '' ? <p className={props.style.columnRowValueEmpty}></p> : <p className={props.style.columnRowValue}>{props.productInfo.get.address}</p>}
                        {props.productInfo.get.name === '' ? <p className={props.style.columnRowValueEmpty}></p> : <p className={props.style.columnRowValue}>{props.productInfo.get.zipcode} {props.productInfo.get.locale}</p>}
                    </div>
                </div>
            </div>
            {props.children}
        </>
    );
}

const ConfigurationWriteView = (props) => {

    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            address: '',
            zipcode: '',
            locale: ''
        },

        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('required field')),
            email: Yup.string().required(t('required field')),
            phone: Yup.string().required(t('required field')),
            address: Yup.string().required(t('required field')),
            zipcode: Yup.string().required(t('required field')),
            locale: Yup.string().required(t('required field'))
        }),
        onSubmit: (values) => {
            alert('ON SUBMIT')
        }
    })

    return (
        <div className={props.style.boxSectionContainer}>
            <div className={props.style.boxSectionContainerColumn}>
                <form onSubmit={props.onSubmitForm} autoComplete="off">
                    <FormControl className={props.style.formControl}>
                        <TextField
                            className={props.style.inputText}
                            id="name"
                            fullWidth
                            label={t('name')}
                            value={props.productInfo.get.name}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, name: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                        />
                        <TextField
                            className={props.style.inputText}
                            id="email"
                            fullWidth
                            label={t('email')}
                            value={props.productInfo.get.email}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, email: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                            disabled
                        /><TextField
                            className={props.style.inputText}
                            id="phone"
                            fullWidth
                            label={t('phone')}
                            value={props.productInfo.get.phone}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, phone: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                        /><TextField
                            className={props.style.inputText}
                            id="address"
                            fullWidth
                            label={t('address')}
                            value={props.productInfo.get.address}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, address: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                        />
                        <TextField
                            className={props.style.inputText}
                            id="zipcode"
                            fullWidth
                            label={t('zipcode')}
                            value={props.productInfo.get.zipcode}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, zipcode: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                        />
                        <TextField
                            className={props.style.inputText}
                            id="locale"
                            fullWidth
                            label={t('locale')}
                            value={props.productInfo.get.locale}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, locale: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                        />
                        {props.children}
                    </FormControl>
                </form>
            </div>
        </div>
    );
}

const ActionContext = (props) => {

    const { t } = useTranslation();

    return (
        <div className={props.style.actionContextContainer}>
            {
                props.config.map((prop, key) => {
                    return (
                        <Button
                            type={prop.type ? prop.type : 'button'}
                            variant="contained"
                            color={prop.color ? prop.color : 'primary'}
                            style={{ marginRight: '20px' }}
                            onClick={prop.onClick}>
                            {t(prop.value)}
                        </Button>
                    );
                })
            }
        </div>
    );
}




/*


<Container maxWidth="sm" >
                        <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    className={classes.inputText}
                                    id="name"
                                    fullWidth
                                    label="Nome do Restaurnate"
                                    autoComplete="off"
                                    style={{marginTop: '10px', marginBottom: '10px'}}
                                />
                                <TextField
                                    className={classes.inputText}
                                    id="name"
                                    fullWidth
                                    label="Email"
                                    autoComplete="off"
                                    style={{marginTop: '10px', marginBottom: '10px'}}
                                /><TextField
                                    className={classes.inputText}
                                    id="name"
                                    fullWidth
                                    label="Telefone"
                                    autoComplete="off"
                                    style={{marginTop: '10px', marginBottom: '10px'}}
                                /><TextField
                                    className={classes.inputText}
                                    id="name"
                                    fullWidth
                                    label="Morada"
                                    autoComplete="off"
                                    style={{marginTop: '10px', marginBottom: '10px'}}
                                />
                                <TextField
                                    className={classes.inputText}
                                    id="name"
                                    fullWidth
                                    label="Código Postal"
                                    autoComplete="off"
                                    style={{marginTop: '10px', marginBottom: '10px'}}
                                />
                                <TextField
                                    className={classes.inputText}
                                    id="name"
                                    fullWidth
                                    label="Localidade"
                                    autoComplete="off"
                                    style={{marginTop: '10px', marginBottom: '10px'}}
                                />
                            </FormControl>
                        </form>
                    </Container>





















                    <TextField
                            className={props.style.inputText}
                            id="name"
                            fullWidth
                            label={t('name')}
                            value={props.productInfo.get.name}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, name: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                        />
                        <TextField
                            className={props.style.inputText}
                            id="email"
                            fullWidth
                            label={t('email')}
                            value={props.productInfo.get.email}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, email: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                            disabled
                        /><TextField
                            className={props.style.inputText}
                            id="phone"
                            fullWidth
                            label={t('phone')}
                            value={props.productInfo.get.phone}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, phone: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                        /><TextField
                            className={props.style.inputText}
                            id="address"
                            fullWidth
                            label={t('address')}
                            value={props.productInfo.get.address}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, address: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                        />
                        <TextField
                            className={props.style.inputText}
                            id="zipcode"
                            fullWidth
                            label={t('zipcode')}
                            value={props.productInfo.get.zipcode}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, zipcode: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                        />
                        <TextField
                            className={props.style.inputText}
                            id="locale"
                            fullWidth
                            label={t('locale')}
                            value={props.productInfo.get.locale}
                            onChange={(event) => props.productInfo.set({ ...props.productInfo.get, locale: event.target.value })}
                            autoComplete="off"
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                            required
                        />
*/