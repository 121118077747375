import React, { useState, useContext, useEffect } from "react";
import { compose } from 'recompose';
import styles from './styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PropTypes } from "prop-types";
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logoMnu from './../../../assets/imgs/logo-mnu.svg';

/* Material-UI */
import {
    withStyles,
    FormControl,
    TextField,
    Typography,
    Grid,
    InputAdornment,
    IconButton,
    Input,
    InputLabel,
    FormHelperText
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'



/* Components */
import AccountDialog from '../../../components/dialogs/AccountDialog';
import ChallengeAccountDialog from '../../../components/dialogs/ChallengeAccountDialog';
import SubmitButton from '../../../components/SubmitButton';

/* Contexts */
import { Context } from "../../../context";
import { LayoutContext } from "../../../context/LayoutContext";

/* Services */
import { setUserData, setToken } from '../../../services/storage';
import * as authService from '../../../services/AuthService/auth.service';

/* Constants */
import * as actionTypes from '../../../actions/actionsType';
import * as STATUS_CODE from '../../../constants/httpCode';

import { ThemeProvider } from "@material-ui/core/styles";

import authTheme from './../../../theme/authTheme';




const Login = ({
    classes
}) => {

    const { t } = useTranslation();

    const { state, dispatch } = useContext(Context);

    const { layoutDispatch } = useContext(LayoutContext);

    const [openAccountDialog, setAccountDialog] = useState(false);
    const [multipleAccount, setMultipleAccount] = useState([]);

    const [openChallengAccount, setOpenChallengAccount] = useState(false);
    const [authChallengeData, setAuthChallengeData] = useState(null);

    const [showPassword, setShowPassword] = useState(false)

    const history = useHistory();

    const urlParams = new URLSearchParams(useLocation().search);
    const email = urlParams.get('email');


    const formik = useFormik({
        validateOnMount: true,
        initialValues: {
            email: email ? email : '',
            password: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email(t('invalid email'))
                .required(t('required field')),
            password: Yup.string()
                .required(t('required field'))
        }),
        onSubmit: async (values) => {
            try {
                const response = await authService.login(values.email, values.password);
                responseLoginHandler(response);
            } catch (error) {
                switch (error.response.data.statusCode) {
                    case 400:
                        formik.setErrors({ login: t('username or password incorrect') });
                        break;
                    default:
                        formik.setErrors({ login: t('error auth login') });
                }
            }
        }
    })


    useEffect(() => {
        if (state.user.isAuthenticated) {
            history.push('/');
        }
    }, [state.user.isAuthenticated, history]);

    const handleClickShowPassword = () => {
        setShowPassword(previous => !previous)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    /* Handler the login by calling the API and according to response dispatch a action (type and data)*/
    const loginHandler = async (tenant) => {
        try {
            layoutDispatch({ type: 'LOADING', data: { loading: true } });
            const response = await authService.login(formik.values.email, formik.values.password, tenant);
            responseLoginHandler(response);
        } catch (error) {
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        }
    };

    /* Handler response login */
    const responseLoginHandler = async (response) => {
        switch (response.data.statusCode) {
            case STATUS_CODE.SUCCESSFULLY_LOGIN: {
                await setToken(response.data.token);
                await setUserData(response.data.data);
                const data = { data: response.data.data, isAuthenticated: true };
                const action = { type: 'AUTH_LOGIN', payload: data };
                dispatch(action);
                showSnackbar("info", t('you are successfully authenticated'));
                history.push('/');
                layoutDispatch({ type: 'LOADING', data: { loading: false } });
                return null;
            }
            case STATUS_CODE.MULTIPLE_TENANT:
                setMultipleAccount([...response.data.tenant]);
                setAccountDialog(true);
                layoutDispatch({ type: 'LOADING', data: { loading: false } });
                return null

            case STATUS_CODE.AUTH_CHALLENGE:
                layoutDispatch({ type: 'LOADING', data: { loading: false } });
                setAuthChallengeData(response.data)
                setOpenChallengAccount(true);
                return null

            default:
                layoutDispatch({ type: 'LOADING', data: { loading: false } });
                break;
        }
    }


    const chooseAccountHandler = (account) => {
        setAccountDialog(false);
        const accountId = account.id;
        loginHandler(accountId);
    }

    const changePasswordHandler = async (password) => {
        layoutDispatch({ type: 'LOADING', data: { loading: true } });
        setOpenChallengAccount(false)
        const postData = {
            challengeName: authChallengeData.ChallengeName,
            session: authChallengeData.Session,
            username: authChallengeData.user.email,
            tenantId: authChallengeData.user.tenantId,
            newPassword: password
        }
        try {
            const response = await authService.authChallenge(postData);
            await setToken(response.data.token);
            await setUserData(response.data.data);
            const data = { data: response.data.data, isAuthenticated: true };
            const action = { type: 'AUTH_LOGIN', payload: data };
            dispatch(action);
            showSnackbar("success", t('your password has been successfully changed'));
            history.push('/');
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        } catch (error) {
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        }
    }




    const accountDialogRender = openAccountDialog ?
        <AccountDialog
            open={openAccountDialog}
            disableBackdropClick
            email={formik.values.email}
            tenants={multipleAccount}
            accountSeleted={chooseAccountHandler}
            closeDialog={() => setAccountDialog(false)} />
        : null;

    const challengeAccountRender = openChallengAccount ?
        <ChallengeAccountDialog
            closeDialog={() => setOpenChallengAccount(false)}
            save={(password) => changePasswordHandler(password)} />
        : null;


    const showSnackbar = (severiry, message) => {
        const snakbar = {
            show: true,
            message: message,
            severiry: severiry
        }
        layoutDispatch({ type: actionTypes.OPEN_SNACKBAR, data: { snakbar: snakbar } });
    }

    const forgotPasswordClickHandler = () => {
        history.push('forgot-password');

    }




    let formRender =
        <form onSubmit={formik.handleSubmit} className={classes.form} >

            {!!formik.errors.login && (
                <Alert
                    className={classes.alertError}
                    variant="filled"
                    severity="error">{formik.errors.login}</Alert>
            )}

            <div className={classes.boxLogoCyc}>
                <img width={117}
                    height={44}
                    alt="Logo" src={logoMnu} />
            </div>
            <Typography className={classes.title} variant="h1">
                {t('login')}
            </Typography>
            <div className={classes.inputContainer}>
                <FormControl
                    fullWidth
                    margin='normal'
                >
                    <TextField
                        id="email"
                        label={t('register email')}
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.email && formik.touched.email}
                        helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                    />
                </FormControl>
                <FormControl fullWidth margin='normal'>
                    <InputLabel htmlFor="standard-adornment-password">{t('password')}</InputLabel>
                    <Input className={classes.inputField}
                        autoFocus
                        margin="dense"
                        fullWidth
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.password && formik.touched.password}
                        helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    className={classes.iconVisibility}
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {(formik.errors.password && formik.touched.password) && formik.errors.password && (
                        <FormHelperText error>
                            {formik.errors.password}
                        </FormHelperText>
                    )}
                </FormControl>
            </div>
            <div className={classes.boxButton}>
                <SubmitButton
                    className={classes.signInButton}
                    type="submit"
                    variant="contained"
                    loadingColor={"#FFFFFF"}
                    isSubmitting={formik.isSubmitting}>
                    {t('sign in')}
                </SubmitButton>
                <p className={classes.linkForgotPassword}
                    onClick={() => forgotPasswordClickHandler()}>
                    {t('forgot password')}
                </p>
            </div>
        </form>



    return (

        <>
            <ThemeProvider theme={authTheme}>
                <div
                    className={classes.root}>
                    <Grid className={classes.gridContainer}
                        container
                        justify='center'
                        alignItems='center'
                        alignContent='center'>
                        {formRender}
                    </Grid>
                </div>
            </ThemeProvider>
            {accountDialogRender}
            {challengeAccountRender}
        </>


    );
}

Login.propTypes = {
    classes: PropTypes.object
}


export default compose(
    withStyles(styles),
)(Login);
