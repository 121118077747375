export default theme => ({
    root: {
     padding: '30px'
    },
    avatarCurrent: {
      width: 60,
      height: 60,
    },
    dialogTitle: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    avatar: {
      backgroundColor: '#282828',
      color: 'white',
    },
    Info: {
      textAlign: 'center'
    },
    list: {
      width: '100%',
      paddingTop: '30px',
    },
    listItem: {
      padding: '30px'
    },
    divider:{
      marginTop: '15px',
      marginBottom: '15px'
    }
  });
  