import React, {useState} from 'react';
import { compose } from 'recompose';


/* Material-UI */
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { updateObject, checkValidity } from '../../../shared/utilities/utility';



const PasswordRequiredDialog = ({
  confirm,
  closeDialog
}) => {

    const [form, setForm] = useState({
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Password'
            },
            value: '',
            name:'Password',
            validation: {
                required: true,
                minLength: 6
            },
            valid: false,
            touched: false
        }
    })
    

  
      /* Close the dialog */
      const handleClose = () => {
        closeDialog();
      };

      /* Pass password to parent  */
      const handleContinue = () => {
        confirm(form.password.value);
      }

      /* Handler input change */
      const inputChangedHandler = prop =>  (event) => {
        const updatedControls = updateObject(form, {
            [prop]: updateObject(form[prop], {
                value: event.target.value,
                valid: checkValidity(
                    event.target.value,
                    form[prop].validation
                ),
                touched: true
            })
        });
        setForm(updatedControls);
    }


    return (
        <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Authentication Required</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Password is required to continue this action.
                <br/>
                Please enter the password.
          </DialogContentText>
          <TextField
            error={!form.password.valid && form.password.touched}
            autoFocus
            margin="dense"
            id={form.password.name}
            label={form.password.name}
            type={form.password.elementConfig.type}
            fullWidth
            value={form.password.value}
            onChange={inputChangedHandler("password")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleContinue}
            color="primary"
            disabled={!form.password.valid}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export default compose()(PasswordRequiredDialog);