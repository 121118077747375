import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import styles from './styles';


/* Material UI */
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';




import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

/* Components */
import MenuLanguage from './components/MenuLanguage';

/* Contexts */
import { Context } from '../../../context';

/* Configs */
import images from '../../../constants/images';

/* Services */
import { clearAllDataSession } from './../../../services/storage';


const Topbar = ({
  classes,
  onSidebarOpen
}) => {

    const { t } = useTranslation();

    const history = useHistory();

    const {state, dispatch} = useContext(Context); 



    /* Logout Handle */
  const logoutHandler = async () => {
     await clearAllDataSession();
     const action = { type: 'AUTH_LOGOUT' };
     dispatch(action);
     history.push('/auth/login');
    }


  const logoutRender = state.user.isAuthenticated ? 
                           <IconButton 
                              onClick={logoutHandler}
                              className={classes.signOutButton}
                              color="inherit"
                             >
                             <ExitToAppOutlinedIcon />
                             <span>{t('sign out')}</span>
                           </IconButton> : null;                   



    const menuLanguageRender = <MenuLanguage/>
 
    
    return (
        <AppBar>
        <Toolbar elevation={0}>
          <Hidden mdDown>
            <img
               width={150} 
               height={52}
               className={classes.logo}
               alt="Logo"
               src={images.logo}
            />
          </Hidden>
          <Hidden lgUp>
            <IconButton
              color="inherit"
              onClick={onSidebarOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
            
            <div className={classes.flexGrow}> 
               {menuLanguageRender}
               {logoutRender}
            </div>
        </Toolbar>
      </AppBar>
    );
}

Topbar.propTypes = {
  classes: PropTypes.object
}

export default compose(
  withStyles(styles)
)(Topbar);