import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { Fade } from 'react-reveal';
import Jimp from 'jimp';
import { ListManager } from "react-beautiful-dnd-grid";


import {
    withStyles,
    Container,
    FormControl,
    TextField,
    Button,
    useMediaQuery,
    Grid,
    Dialog,
    DialogContent,
    Checkbox
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import {
    DragDropContext,
    Droppable,
    Draggable
} from 'react-beautiful-dnd';

import RotateImageDialog from '../../../../components/dialogs/EditionImageDialog';

/* Components */
import ProgressLinear from './../../../../components/ProgressLinear';
import Loader from './../../../../components/Loader/Loader.js'


/* Utilities */
import { getFileMimeType } from '../../../../shared/utilities/utility';

/* Services */
import * as ementaService from '../../../../services/ementaService/ementa.service';

/* Contexts */
import { Context } from "../../../../context";
import { LayoutContext } from "../../../../context/LayoutContext.js";




const MAX_SIZE_FILE = 5000000


const FormEmenta = ({
    classes,
    t,
    createMenu,
    updateMenu,
    menu,
    productName,
    onCreate,
    onUpdate,
    onCancel,
    creating,
    editing
}) => {


    const { state } = useContext(Context);
    const [tenantName, setTenantName] = useState("");
    const [files, setFiles] = useState([]);
    const [deleteEnabled, setDeleteEnabled] = useState(-1);
    const [uploading, setUploading] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [filesNameRejeted, setFilesNameRejeted] = useState([])
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [rejectedImage, setRejectedImage] = useState('');
    const [openRotateImage, setOpenRotateImage] = useState(false);
    const [fileToRotate, setFileToRotate] = useState(null);
    const [base64ImagRotate, setBase64ImageRotate] = useState(null);
    const { layoutState, layoutDispatch } = useContext(LayoutContext);


    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
    });

    const userTenantName = state.user.data.tenantName

    useEffect(() => {
        try {
            setTenantName(userTenantName);
        } catch (error) {
            setTenantName("");
        }
        setDisableSubmit(false);
    }, [menu, userTenantName])




    const formik = useFormik({
        initialValues: updateMenu && menu ? {
            name: menu.name,
        } : {
                name: productName
            },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('required field'))
        }),
        onSubmit: (values) => {
           
            setDisableSubmit(true);
            if (!createMenu) {
                const data = {
                    name: values.name,
                    files: files
                }
                onUpdate(data)
            } else {
                const data = {
                    name: values.name,
                    assetsSk: files.map(data => data.sk),
                    files: files
                }
                onCreate(data)
            }
        }
    })

    useEffect(() => {
        if (updateMenu && menu) {
            const assetsMenu = menu.content.assets;
            const filesFromMenu = assetsMenu.map((asset, index) => {
                return {
                    url: asset.hasOwnProperty('url_compress') ? asset.url_compress : asset.url,
                    bucketKey: asset.bucket_key,
                    sk: null,
                    hasAsset: true,
                    enable: asset.enable,
                    isNew: false,
                    id: asset.id,
                    type: asset.type,
                    order: index
                }
            })
            setFiles(files => [...files, ...filesFromMenu]);
        }
    }, [menu, updateMenu])



    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxSize: MAX_SIZE_FILE,
        accept: ['image/jpeg', 'image/png', 'image/jpg'],
        onDrop: async acceptedFiles => {
            await processAcceptedFiles(acceptedFiles)
        },
        onDropRejected: (fileRejections) => {
            let errorSize;
            if (fileRejections && fileRejections.length) {
                fileRejections.forEach(rejected => {
                    errorSize = rejected.errors.filter(error => error.code === "file-too-large")
                    if (errorSize.length > 0) {
                        setRejectedImage('SIZE')
                        setFilesNameRejeted(previous => [...previous, rejected.file.name]
                        )
                    }
                    errorSize = rejected.errors.filter(error => error.code === "file-invalid-type")
                    if (errorSize.length > 0) {
                        setRejectedImage('FORMAT')
                        setFilesNameRejeted(previous => [...previous, rejected.file.name]
                        )
                    }
                })
                setOpenPopup(true);
            }
        }
    });


    // Get files and call api for each file to get URL to upload the file to S3
    const processAcceptedFiles = async (acceptedFiles) => {
        const datasUpload = []
        const filesUploaded = [];
        setUploading(true);
        /* Get url to upload files and upload file */
        await Promise.all(
            await acceptedFiles.map(async (file, index) => {
                const fileInfo = await getFileMimeType(file);
                fileInfo['index'] = index;
                try {
                    const response = await ementaService.getUploadFileUrl(fileInfo);
                    const dataUpload = response.data.data;
                    datasUpload.push(dataUpload)
                } catch (error) {
                    // 
                }
            })
        )
        // Upload files to the URL received 
        Promise.all(datasUpload.map(async (dataUpload, index) => {
            await ementaService.uploadFile(dataUpload.url, acceptedFiles[index], async (progress) => { })
        })).then(async () => {
            await Promise.all(
                datasUpload.map(async (dataUpload, index) => {
                    try {
                        const params = { "key": dataUpload['bucketKey'] };
                        const resp = await ementaService.getImage(params)
                        const fileUploaded = {
                            isNew: dataUpload.isNew,
                            type: dataUpload.type,
                            extension: dataUpload.extension,
                            id: dataUpload.id,
                            bucketKey: dataUpload.bucketKey,
                            sk: dataUpload.sk,
                            url: resp.data,
                            hasAsset: false,
                            enable: true,
                            order: files.length + (index + 1)
                        }
                        filesUploaded.push(fileUploaded)
                    } catch (error) {
                        //
                    }
                })
            )

            setFiles(files => [...files, ...filesUploaded]);
            setUploading(false);
        })
    }



    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const itemsSorted = reorder(
            files,
            result.source.index,
            result.destination.index
        );
        setFiles(itemsSorted);
    }

    const enableDelete = index => {
        setDeleteEnabled(index);
    }

    const disableDelete = () => setDeleteEnabled(null);

    const removeImage = index => {
        const newFiles = files.filter((file, i) => i !== index);
        setFiles(newFiles);
    };

    const rotateImageHandler = async item => {
        const file = item
        try {
            const params = { "key": file.bucketKey };
            const resp = await ementaService.getImage(params)
            setFileToRotate(file)
            layoutDispatch({ type: 'LOADING', data: { loading: true } });
            Jimp.read({
                url: resp.data
            }
            ).then(img => {
                img.getBase64(Jimp.AUTO, (err, res) => {
                    setBase64ImageRotate(res)
                    layoutDispatch({ type: 'LOADING', data: { loading: false } });
                    setOpenRotateImage(true);
                })
            }).catch(error => {
                layoutDispatch({ type: 'LOADING', data: { loading: false } });

            })
        } catch (error) {
            layoutDispatch({ type: 'LOADING', data: { loading: false } });
        }
    }



    const onSaveImageProcessed = async (file, image) => {
        setUploading(true);
        const datasUpload = []
        const filess = [];
        
        setOpenRotateImage(false)
        const index = files.indexOf(file);
        let extension = 'jpeg';
        if (image.charAt(0) == '/') {
            extension = "jpeg";
        }
        if (image.charAt(0) == 'i') {
            extension = "png";
        }

        const fileInfo = {
            extension: extension,
            index: index,
            type: "image",
            bucketKey: file.bucketKey
        }

        try {
            const response = await ementaService.getUploadFileUrl(fileInfo);
            const dataUpload = response.data.data;
            datasUpload.push(dataUpload)
        } catch (error) {
            //
        }

        Promise.all(datasUpload.map(async (dataUpload) => {
            await ementaService.uploadFile(dataUpload.url, dataURLtoFile(image, 'file edited'), async (progress) => { })
        })).then(async () => {
            await Promise.all(
                datasUpload.map(async dataUpload => {
                    try {
                        const params = { "key": dataUpload['bucketKey'] };
                        const resp = await ementaService.getImage(params)
                        const fileUploaded = {
                            isNew: file.isNew,
                            type: dataUpload.type,
                            extension: dataUpload.extension,
                            id: dataUpload.id,
                            bucketKey: dataUpload.bucketKey,
                            sk: dataUpload.sk,
                            url: resp.data,
                            hasAsset: file.hasAsset,
                            enable: true,
                            edited: true
                        }
                        filess.push(fileUploaded)
                    } catch (error) {
                        //
                    }
                })
            )
            let filesCopy = [...files]
            filesCopy[index] = filess[0]
            setFiles(filesCopy);
            setUploading(false);
        })

    }

    const handleChangeStatus = (file) => (event) => {
        const fl = files.find(fl => fl.bucketKey === file.bucketKey);
        if (fl) {
            const index = files.indexOf(fl)
            if (index > -1) {
                const filesCopy = [...files];
                filesCopy[index] = { ...fl, enable: event.target.checked }
                setFiles(filesCopy)
            }
        }
    }

    const deleteImageHandler = (file) => {
        setOpenRotateImage(false)
        const fl = files.find(fl => fl.bucketKey === file.bucketKey);
        if (fl) {
            const index = files.indexOf(fl)
            removeImage(index)
        }
    }

    const reorderList = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
            }

        const list = files;

        if (destinationIndex === 0) {
            list[sourceIndex].order = list[0].order - 1;
            setFiles(sortList(files))
            return;
        }

        if (destinationIndex === list.length - 1) {
            list[sourceIndex].order = list[list.length - 1].order + 1;
            setFiles(sortList(files))
            return;
        }

        if (destinationIndex < sourceIndex) {
            list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
            setFiles(sortList(files))
            return;
        }

        list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
        setFiles(sortList(files))
    }

   


    {/*
     const dragBoxThumsRender =
        files && files.length ?
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        className={classes.dragableList}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}>
                        {files.map((file, index) => (
                            <Draggable key={file.bucketKey} draggableId={file.bucketKey} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}>
                                        {<div
                                            className={classes.thumb}
                                            style={isSmallDevice ? {
                                                width: 70,
                                                height: 70,
                                            } : {
                                                    width: 100,
                                                    height: 100,
                                                }}
                                            key={file.bucketKey}
                                            onMouseEnter={() => enableDelete(index)}
                                            onTouchStart={() => enableDelete(index)}
                                            onMouseLeave={disableDelete}>
                                            <Fade duration={100} when={true}>
                                                    <div className={classes.boxButtom}>
                                                        <Checkbox
                                                            className={classes.checkBoxEnableImage}
                                                            checked={file.enable}
                                                            onChange={handleChangeStatus(file)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </Fade>
                                                <div className="thumb-overlay" onClick={() => rotateImageHandler(index)}></div>
                                                <div className={classes.thumbInner}>
                                                    <img
                                                        src={file.url}
                                                        className={classes.img}
                                                        alt="thumb" />
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext> : null
    */ }


    const dragBoxThumsRender2 =
        <ListManager
        items={files}
        direction="horizontal"
        maxItems={4}
        render={(item, index) => (
                <div
                    className={classes.thumb}
                    style={isSmallDevice ? {
                        width: 70,
                        height: 70,
                    } : {
                            width: 100,
                            height: 100,
                        }}
                    key={item.bucketKey}
                    onMouseEnter={() => enableDelete(index)}
                    onTouchStart={() => enableDelete(index)}
                    onMouseLeave={disableDelete}>
                    <Fade duration={100} when={true}>
                        <div className={classes.boxButtom}>
                            <Checkbox
                                className={classes.checkBoxEnableImage}
                                checked={item.enable}
                                onChange={handleChangeStatus(item)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                    </Fade>
                    <div className="thumb-overlay" onClick={() => rotateImageHandler(item)}></div>
                    <div className={classes.thumbInner}>
                        <img
                            src={item.url}
                            className={classes.img}
                        alt="thumb" />
                   
                    </div>
                </div>
        )}
        onDragEnd={reorderList}

        />
    
  



    const actionsCreate =
        <>
            <Grid item>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={(files.length === 0) | disableSubmit}>
                    {t('generate qrcode')}
                </Button>
            </Grid>
            <Grid item>
                {files.length ?
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setFiles([])
                            formik.resetForm({ name: '' })
                        }}>
                        {t('cancel')}
                    </Button> : null
                }
            </Grid>
        </>


    const actionsEdit =
        <>
            <Grid item>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={(formik.errors.name && formik.touched.name) | disableSubmit}>
                    {t('save')}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => { onCancel() }}>
                    {t('cancel')}
                </Button>
            </Grid>
        </>


    const closePopup = () => {
        setOpenPopup(false)
        setFilesNameRejeted([])
        setRejectedImage('FORMAT')
    }
    const popup =
        <Dialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={openPopup}>
            <DialogContent>
                <div className={classes.boxError}>
                    <ErrorIcon className={classes.errorIcon} />
                    <p className="title">{t('rejected files title')}</p>
                    {filesNameRejeted.map((fileName, index) => (
                        rejectedImage === 'SIZE' ?
                            <p key={index} className="info">{t('rejected files info 1')}&nbsp;<span>{fileName}</span>&nbsp;{t('rejected files info 2')}</p>
                            :
                            <p key={index} className="info">{t('rejected files format info 1')}&nbsp;<span>{fileName}</span>&nbsp;{t('rejected files format info 2')}</p>
                    ))}
                    <p className="info">{t('rejected files format info 3')}</p>
                    <p className={classes.buttonClose} onClick={() => closePopup()}>
                        {t('close')}
                    </p>
                </div>
            </DialogContent>
        </Dialog>


    const rotateImagePopup =
        <Dialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={openRotateImage}>
            <DialogContent className={classes.dialogContentRotateImage}>
                <RotateImageDialog
                    file={fileToRotate}
                    imgBase64={base64ImagRotate}
                    saveImage={(file, image) => onSaveImageProcessed(file, image)}
                    deleteImage={deleteImageHandler}
                    close={() => setOpenRotateImage(false)} />
            </DialogContent>
        </Dialog>


    return (
        <>
            <div className={classes.root}>
                <div className={classes.boxHeader}>
                    {createMenu ? t('add menu') : t('edit menu')}
                </div>
                <div className={classes.boxContent}>
                    <Container maxWidth="sm" >
                        <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    className={classes.inputText}
                                    id="name"
                                    fullWidth
                                    label={t('menu name')}
                                    autoComplete="off"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.name && formik.touched.name}
                                    helperText={(formik.errors.name && formik.touched.name) && formik.errors.name}
                                />
                            </FormControl>
                            <div {...getRootProps({ className: classes.dropzone })}>
                                {uploading ?
                                    <div className={classes.boxProgress}>
                                        <ProgressLinear />
                                    </div>
                                    : (
                                        creating || editing ?
                                            <>
                                                <div className={classes.infoBoxInfoGenerating}>
                                                    {creating ?
                                                        <p>{t('we are generating your qrcode')}</p> : <p>{t('preparing your menu')}</p>
                                                    }
                                                    <div className={classes.boxProgress}>
                                                        <ProgressLinear />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <p>{t('add photos')}</p>
                                                <p>{t('drag photos here or click in')}:</p>
                                                <Button type="button" variant="outlined" disableElevation onClick={open} className={classes.buttonUpload}>
                                                    {t('upload images')}
                                                </Button>
                                                <p className={classes.sizeInfo}>{t('upload images warning')}</p>
                                            </>
                                    )
                                }
                                <input {...getInputProps()} />
                            </div>

                            {files && files.length ?
                                <div className={classes.thumbsRoot}>
                                    <p>{t('drag photos to sort')}:</p>
                                    <div className={classes.thumsContainer}>
                                        {dragBoxThumsRender2}
                                    </div>
                                    <p className={classes.pwaMessage}>{t('pwa visible images')}</p>
                                </div>
                                : null}

                            <Grid container justify="flex-start" spacing={2} className={classes.gridActionButton}>
                                {createMenu ? actionsCreate : actionsEdit}
                            </Grid>

                        </form>
                    </Container>
                </div>
            </div>
            {popup}
            {rotateImagePopup}
            {layoutState.loading ? <Loader /> : null}
        </>
    )
}

FormEmenta.propTypes = {
    t: PropTypes.any,
    classes: PropTypes.object,
    menu: PropTypes.object,
    productName: PropTypes.string,
    createMenu: PropTypes.bool,
    updateMenu: PropTypes.bool,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
    onCancel: PropTypes.func,
    creating: PropTypes.bool,
    editing: PropTypes.bool,
}

export default compose(
    withStyles(styles),
    withTranslation()
)(FormEmenta)



// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    paddingRight: '16px',
    margin: `0 0 ${grid}px 0`,
    color: 'white',

    // change background colour if dragging
    background: isDragging ? 'white' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'white' : "white",
    padding: grid,
    display: 'flex',
    overflowX: 'auto'
});


function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}


/**
 * convertImgToBase64
 * @param  {String}   url
 * @param  {Function} callback
 * @param  {String}   [outputFormat='image/png']
 * @author jocelino
 */
function convertImgToBase64(url, callback, outputFormat) {

    var canvas = document.createElement('CANVAS');
    var ctx = canvas.getContext('2d');
    var img = new Image;
    img.crossOrigin = '*';
    img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL(outputFormat || 'image/png');
        callback.call(this, dataURL);
        canvas = null;
    };
    img.src = url;
}

function sortList(list) {
    return list.slice().sort((first, second) => first.order - second.order);
}